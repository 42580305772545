import {ActivatedRouteSnapshot, Routes} from '@angular/router';
import {AppConstants} from './AppConstants';
import {RegisterComponent} from './register/register.component';
import {AuthenticationGuardService} from './authentication-guard.service';
import {ValidateEmailComponent} from './validate-email/validate-email.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {ViewProfileComponent} from './view-profile/view-profile.component';
import {AuthenticateDeviceComponent} from './authenticate-device/authenticate-device.component';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {CanDeactivateGuardService} from './can-deactivate-guard.service';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {ConfigurationGuardService} from './ui-configuration/configuration-guard.service';
import {ConfigurationModel} from './ui-configuration/configuration-service';
import {ConfigureTotpComponent} from './configure-totp/configure-totp.component';
import {JoinComponent} from './join/join.component';
import {HomeComponent} from './home/home.component';
import {environment} from '../environments/environment';
import {BsSampleComponent} from './bs-sample/bs-sample.component';
import {NOT_FOUND_ROUTE} from './NotFoundRoute';
import {ManageEmailsComponent} from './manage-emails/manage-emails.component';
import {ApiVersionGuardService} from './api-version-guard.service';
import {AuthenticationState} from './app-state.service';
import {AgreementsComponent} from './agreements/agreements.component';
import {ManageAgreementsComponent} from './manage-agreements/manage-agreements.component';
import {ActivateComponent} from './activate/activate.component';
import {ConnectDeviceComponent} from './connect-device/connect-device.component';

export const AppRoutes: Routes = [
  {
    path: AppConstants.ROUTE_REGISTER,
    component: RegisterComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'Register',
      validateSession: AuthenticationGuardService.hasNoSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_VALIDATE_EMAIL,
    component: ValidateEmailComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'ValidateEmail',
      validateSession: AuthenticationGuardService.hasPartialSession,
    },
  },
  {
    path: AppConstants.ROUTE_VALIDATE_RECOVERY_EMAIL,
    component: ValidateEmailComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'ValidateRecoveryEmail',
      recoveryEmail: true,
      validateSession: AuthenticationGuardService.hasPartialSession,
    },
  },
  {
    path: AppConstants.ROUTE_FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'ForgotPassword',
      validateSession: AuthenticationGuardService.hasNoSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_RESET_PASSWORD,
    component: LoginComponent,
    canActivate: [ApiVersionGuardService],
    data: {
      animation: 'ResetPassword',
    },
  },
  {
    path: AppConstants.ROUTE_ACTIVATE,
    component: ActivateComponent,
    canActivate: [ApiVersionGuardService],
    data: {
      animation: 'ActivateComponent',
    },
  },
  {
    path: AppConstants.ROUTE_LOGIN,
    component: LoginComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'Login',
      validateSession: (auths: AuthenticationState, route: ActivatedRouteSnapshot) => {
        // turns out that even though the router claims to be url based, we land here after route navigation without any of the routes
        // path or params in the url. No idea how the routing actually works, but it is obvious that the url cannot be trusted and all url
        // based logic is likely faulty.
        const noSession = AuthenticationGuardService.hasNoSession(auths);
        const params = route.queryParams;
        return noSession ||
          !!params[AppConstants.QP_ERROR];
      },
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_LOGOUT,
    component: LogoutComponent,
    canActivate: [ApiVersionGuardService],
    data: { animation: 'Logout' },
  },
  {
    path: AppConstants.ROUTE_PROFILE,
    component: ViewProfileComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: { animation: 'ViewProfile' },
  },
  {
    path: AppConstants.ROUTE_DEVICE_AUTH,
    component: AuthenticateDeviceComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: { animation: 'AuthenticateDevice' },
  },
  {
    path: AppConstants.ROUTE_CONNECT_DEVICE,
    component: ConnectDeviceComponent,
    canActivate: [ApiVersionGuardService],
    data: {
      animation: 'AttachDevice',
    },
  },
  {
    path: AppConstants.ROUTE_EDIT_PROFILE,
    component: EditProfileComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    canDeactivate: [CanDeactivateGuardService],
    data: { animation: 'EditProfile' },
  },
  {
    path: AppConstants.ROUTE_CHANGE_PASSWORD,
    component: ChangePasswordComponent,
    canActivate: [ApiVersionGuardService, ConfigurationGuardService, AuthenticationGuardService],
    data: {
      animation: 'ChangePassword',
      blockAccess: (props: ConfigurationModel) => {
        return !props.profileLoginFields?.find((f) => f.key === 'password' && f.readonly !== true);
      }
    },
  },
  {
    path: AppConstants.ROUTE_MANAGE_EMAILS,
    component: ManageEmailsComponent,
    canActivate: [ApiVersionGuardService, ConfigurationGuardService, AuthenticationGuardService],
    data: {
      animation: 'ManageEmails',
      blockAccess: (props: ConfigurationModel) => {
        return !props.profileLoginFields?.find((f) => f.key === 'email' && f.readonly !== true);
      }
    },
  },
  {
    path: AppConstants.ROUTE_CONFIGURE_TOTP,
    component: ConfigureTotpComponent,
    canActivate: [ApiVersionGuardService, ConfigurationGuardService, AuthenticationGuardService],
    data: {
      animation: 'ConfigureTotp',
      blockAccess: (props: ConfigurationModel) => {
        return !props.profileLoginFields?.find((f) => f.key === 'totp' && f.readonly !== true);
      }
    },
  },
  {
    path: AppConstants.ROUTE_JOIN,
    component: JoinComponent,
    canActivate: [ApiVersionGuardService],
    data: {
      // Animation value is relevant only when it changes, so to allow animation from join to join, we must include something that changes.
      // anything after a ":" will be replaced by current value of a matching param or queryParam when resolving the animation to use.
      // multiple values can be separated with a ","
      animation: 'Join:' + AppConstants.QP_INVITATION_ID,
      // bypasses the angular stupidity of not triggering navigation events, if consequent routes happen to use the same primary component.
      neverIgnoreNavigation: true,
    },
  },
  {
    path: AppConstants.ROUTE_AGREEMENTS,
    component: AgreementsComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'Agreements',
      validateSession: AuthenticationGuardService.hasOnlyPartialSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_MANAGE_AGREEMENTS,
    component: ManageAgreementsComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'ManageAgreements',
    },
  },
  {
    path: AppConstants.ROUTE_HOME,
    component: HomeComponent,
    canActivate: [ApiVersionGuardService, AuthenticationGuardService],
    data: {
      animation: 'Home',
      validateSession: AuthenticationGuardService.never,
      showAfterValidateSession: false,
    },
  },
  NOT_FOUND_ROUTE
];
if (environment.production !== true) {
  AppRoutes.unshift({
    path: AppConstants.ROUTE_BS_SAMPLE,
    component: BsSampleComponent,
    canActivate: [ApiVersionGuardService],
    data: { animation: 'BS_SAMPLE' },
  });
}
