import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {AppStateService} from './app-state.service';

/**
 * Yet another unnessesarily complicated angular quirk. Routes just refuse to work with any conditionality in the inclusion of the outlet.
 * So instead of simply including/excluding the whole router outlet, based on a simple boolean condition in the template, we're forced to
 * add a route guard for every single route and use the appState service to pass in the condition.
 */
@Injectable()
export class ApiVersionGuardService implements CanActivate {
  constructor(
    private appStateService: AppStateService) {
  }
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve: any) => {
      const subscription = this.appStateService.subscribeInitState((aps: AppStateService) => {
        const isValid = aps.getAppState().isValidAPI;
        if (isValid !== undefined) {
          if (subscription) {
            subscription.unsubscribe();
          }
          resolve(isValid === true);
        }
      });
    });
  }
}
