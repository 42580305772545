
function addTrailingZerosToVersion(pa: string[]) {
  const retVal = ([] as string[]).concat(pa);
  if (retVal.length < 1) {
    retVal.push('0');
  }
  if (retVal.length < 2) {
    retVal.push('0');
  }
  if (retVal.length < 3) {
    retVal.push('0');
  }
  return retVal;
}

/**
 * Semantic version comparison
 * @return -1 if b is greater than a, 1 if a greater than b and 0 if the 2 are equal
 */

export function compareVersions(a: string, b: string) {
  const pa = addTrailingZerosToVersion(a.split('.'));
  const pb = addTrailingZerosToVersion(b.split('.'));
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) {
      return 1;
    }
    if (nb > na) {
      return -1;
    }
    if (!isNaN(na) && isNaN(nb)) {
      return 1;
    }
    if (isNaN(na) && !isNaN(nb)) {
      return -1;
    }
  }
  return 0;
}
/**
 * Compare semantic version strings. A valid version a must have the same major and a same or greater minor,
 * patch is ignored
 */
export async function isValidVersion(
  test: string,
  required: string
): Promise<boolean> {
  const as = test.split('.');
  const bs = required.split('.');
  if (compareVersions(as[0], bs[0]) === 0) {
    // same major
    if (
      compareVersions(
        as[0] + (as.length > 1 ? '.' + as[1] : ''),
        bs[0] + (bs.length > 1 ? '.' + bs[1] : '')
      ) >= 0
    ) {
      // same or greater minor
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

