<div
  class="card standalone logout-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <h1 class="card-header text-center">
    {{'logout.title'|translate}}
  </h1>
  <div class="card-body">
    <p class="text-center">{{'logout.copy'|translate}}</p>
    <div class="loader-wrapper position-relative d-block mb-2">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <ul *ngIf="consumers.length" class="list-unstyled hiddenFrameHolder">
      <li *ngFor="let item of consumers">
        <iframe [src]="getConsumerLogoutUrl(item)"></iframe>
      </li>
    </ul>
  </div>
</div>
