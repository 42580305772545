import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../AppConstants';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';



@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageNotFoundComponent extends StandaloneComponent implements OnInit {
  page: string;
  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private translate: TranslateService,
    private appStateService: AppStateService,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
  }

  ngOnInit() {
    super.init();
    this.page = this.router.url
      .replace(/(\W)/g, '<wbr />$1');
  }

  getContinueLink(): string {
    let retVal: string;
    if (this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      retVal = AppConstants.PATH_PROFILE;
    } else {
      retVal = AppConstants.PATH_LOGIN;
    }
    return retVal;
  }

  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('page-not-found.window-title');
  }
}
