import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../AppConstants';
import {ParamsService} from '../params.service';
import {Logger} from '../../util/logger';
import {Observable} from 'rxjs';
import {
  AgreementAcceptance,
  AgreementService,
} from '../../gen';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';
import {ProcessingIndicatorService} from '../processing-indicator.service';
import {
  AcceptAgreementChallengeWithReceivedTime, listUsersAgreementsWithReceivedTime,
} from '../../util/AgreementUtils';
import {FormInputComponent} from '../form-input/form-input.component';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-manage-agreements',
  templateUrl: './manage-agreements.component.html',
  styleUrls: ['./manage-agreements.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageAgreementsComponent extends StandaloneComponent implements OnInit, OnDestroy {

  private destroyed = false;
  noAgreementsFound = false;
  loadingFailed = false;
  submitFailed = false;
  userAgreements: AcceptAgreementChallengeWithReceivedTime[] | undefined;
  agreementsForm;

  constructor(
    @Inject('AgreementApi') private agreementApi: AgreementService,
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private translate: TranslateService,
    private logger: Logger,
    private processingService: ProcessingIndicatorService,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
  }

  ngOnInit() {
    super.init();

    listUsersAgreementsWithReceivedTime(this.agreementApi).then((agr) => {
      if (!!agr) {
        this.userAgreements = agr.agreements;
        this.loadingFailed = false;
        if (this.userAgreements && this.userAgreements.length) {
          this.noAgreementsFound = false;
          const controls = {};

          for (let i = 0; i < this.userAgreements.length; i += 1) {
            const validators = [];
            if (this.userAgreements[i].required) {
              validators.push(Validators.requiredTrue);
            }
            controls['agreements_' + i] = new FormControl({
                value: this.isAccepted(this.userAgreements[i]),
                disabled: this.isDisabled(this.userAgreements[i])
              },
              { validators, updateOn: 'change' });
          }
          this.agreementsForm = new FormGroup(controls);

        } else {
          this.noAgreementsFound = true;
        }
      } else {
        this.noAgreementsFound = true;
      }
    }, (err) => {
      this.loadingFailed = true;
    });
  }

  isDisabled(t: AcceptAgreementChallengeWithReceivedTime): boolean {
    const retVal = t && t.required && ((t.accepted && t.accepted > 0) || (t.declined && t.declined > 0)) ? true : false;
    return retVal;
  }

  isAccepted(t: AcceptAgreementChallengeWithReceivedTime): boolean {
    const retVal = t && t.accepted && t.accepted > 0 ? true : false;
    return retVal;
  }
  isRejected(t: AcceptAgreementChallengeWithReceivedTime): boolean {
    const retVal = t && t.declined && t.declined > 0 ? true : false;
    return retVal;
  }
  getAgreementNameLink(a: AcceptAgreementChallengeWithReceivedTime) {
    if (a.location) {
      return '<a class="name" href="' + a.location + '" rel="noopener" target="_blank">' + a.name + '</a>';
    } else {
      return '<span class="name">' + a.name + '</span>';
    }
  }

  hasOptional() {
    return !!this.userAgreements.find((a) => !a.required);
  }

  hasRequired() {
    return !!this.userAgreements.find((a) => a.required);
  }

  getAgreementResponseTimeAsString(a: AcceptAgreementChallengeWithReceivedTime) {
    const format = 'longDate';
    const locale = this.translate.currentLang || this.translate.defaultLang;
    const formattedDate = formatDate(
      new Date(a.accepted || a.declined || (a.responseExpectedInSeconds * 1000 + a.received)), format, locale);
    return '<strong>' + formattedDate + '</strong>';
  }
  ngOnDestroy() {
    this.destroyed = true;
  }

  hasFormChanges() {
   const bigfucker = this.userAgreements?.map((a, i) => {
      const lilFucker = this.agreementsForm.get('agreements_' + i) &&
        this.agreementsForm.get('agreements_' + i).value === this.isAccepted(this.userAgreements[i]);
      return lilFucker;
    }).findIndex((t) => {
      return t !== true;
    }) >= 0;
   return bigfucker;
  }

  isFieldInvalid(field: string): boolean {
    if (field === 'agreements') {
      const count = this.userAgreements ? this.userAgreements.length : 0;
      if (count > 0) {
        for (let i = 0; i < count; i += 1) {
          const tmp = this.isFieldInvalid(field + '_' + i);
          if (tmp) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      return this.hasError(field) || this.isInputInvalid(field);
    }
  }

  isInputInvalid(field: string): boolean {
    if (field.startsWith('agreements_')) {
      const siblingsActive = [...Array(this.userAgreements ? this.userAgreements.length : 0).keys()]
        .map((i) => this.agreementsForm.get('agreements_' + i)?.touched && this.agreementsForm.get('agreements_' + i)?.value)
        .find((f) => f)
      ;
      return this.agreementsForm.get(field)?.invalid && siblingsActive;
    } else {
      return this.agreementsForm.get(field).invalid && this.agreementsForm.get(field).touched;
    }
  }

  isFieldValid(field: string): boolean {

    if (field === 'agreements') {
      const count = this.userAgreements ? this.userAgreements.length : 0;
      if (count > 0) {
        for (let i = 0; i < count; i += 1) {
          const tmp = this.isFieldValid(field + '_' + i);
          if (!tmp) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    } else {
      return !this.hasError(field) && this.isInputValid(field);
    }
  }

  isInputValid(field: string): boolean {
    if (field.startsWith('agreements_')) {
      const siblingsTouched = [...Array(10).keys()]
        .map((i) => this.agreementsForm.get('agreements_' + i)?.touched)
        .find((f) => f)
      ;
      return this.agreementsForm.get(field)?.valid && siblingsTouched;
    } else {
      return this.agreementsForm.get(field).valid && this.agreementsForm.get(field).touched;
    }
  }

  hasError(field?: string, code?: string): boolean {
    // submit error handling not currently used to highlight inputs
    return false;
  }
  resolveFieldValueForIncludedField(field: string): string | boolean | undefined {
    return this.agreementsForm.get(field).value;
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputWrapperClass(): string {
    return FormInputComponent.WRAPPER_CLASS + ' checkbox-group';
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputControlWrapperClass(): string {
    return FormInputComponent.CONTROL_WRAPPER_CLASS;
  }

  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.route,
    });
  }

  onSubmit() {
    const agrCreds: AgreementAcceptance[] = this.userAgreements.map((a, i) => {
      if (!a.required || (!a.declined && !a.accepted)) {
        return {
          agreementId: a.agreementId,
          accept: this.resolveFieldValueForIncludedField('agreements_' + i),
          authenticationMethod: AppConstants.AC_AM_ACCEPT_AGREEMENT,
        } as AgreementAcceptance;
      } else {
        return null;
      }
    }).filter((f) => !!f);

    const handler = (response) => {
      this.close();
    };
    const errorHandler = (response) => {
      this.submitFailed = true;
    };
    this.appStateService.addAuthentications(agrCreds, false).subscribe(handler, errorHandler);
  }

  close() {
    this.router.navigate([AppConstants.PATH_PROFILE], {
      queryParams: {},
    });
  }

  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('manage-agreements.window-title');
  }
}
