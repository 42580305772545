<div
  class="card standalone connect-device-component"
  [ngClass]="{
    'show': isReady(),
    'error': !url || urlRejected
  }"
>
  <h1 class="card-header text-center">
    <span class="icon d-block mx-auto fa fa-fw fa-2x fa-exclamation-triangle mb-2" *ngIf="!url || urlRejected"></span>
    {{'connect-device.title'|translate}}
  </h1>
  <ng-container *ngIf="!url">
    <div class="card-body">
      <p class="text-center mb-0"
        [innerHTML]="'connect-device.required-params-missing'|translate: { param: '<strong>' + getAppConstant('QP_CONNECT_HANDLER') + '</strong>'}"
      ></p>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          data-test-cancel
          class="btn btn-secondary btn-block btn-lg"
          type="button"
          (click)="cancel()"
        >
          {{'connect-device.cancel-label'|translate}}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!!url && !!urlRejected">
    <div class="card-body">
      <p class="text-center mb-0"
         [innerHTML]="'connect-device.url-not-allowed'|translate: { url: '<strong>' + url + '</strong>'}"
      ></p>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          data-test-cancel
          class="btn btn-secondary btn-block btn-lg"
          type="button"
          (click)="cancel()"
        >
          {{'connect-device.cancel-label'|translate}}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!!url && !urlRejected">
    <div class="card-body">
      <p class="text-center">{{'connect-device.info'|translate}}</p>
      <p class="text-center mb-0">{{'connect-device.info2'|translate}}</p>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          data-test-cancel
          class="btn btn-primary ml-auto"
          type="button"
          (click)="continue()"
        >
          {{'connect-device.continue-label'|translate}}
        </button>
        <button
          data-test-cancel
          class="btn btn-secondary mr-auto"
          type="button"
          (click)="cancel()"
        >
          {{'connect-device.cancel-label'|translate}}
        </button>
      </div>
    </div>
  </ng-container>
</div>
