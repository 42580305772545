<div
  class="card standalone fade page-not-found-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      <span class="icon d-block mx-auto fa fa-fw fa-2x fa-exclamation-triangle mb-2"></span>
      {{'page-not-found.title'|translate}}
    </h1>
  </div>
  <div class="card-body text-center">
    <p
      class="mb-0"
      [innerHTML]="'page-not-found.copy'|translate:{page: page}"
    ></p>
  </div>
  <div class="card-footer">
    <a
      class="btn btn-lg btn-block btn-secondary"
      [routerLink]="[getContinueLink()]"
      queryParamsHandling="merge"
    >
      {{'page-not-found.continue.button.label'|translate}}
    </a>
  </div>
</div>
