/**
 * 10Duke End User Identity API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiError } from '../model/apiError';
import { SsoSessionsExistError } from '../model/ssoSessionsExistError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SessionService {

    protected basePath = 'http://localhost:8080/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Ends an authenticated session
     * Ends the current authenticated session, in effect logging the user out
     * @param ignoreConsumerSingleSignOut Flag for ending the authenticated session also in the case that there are consumers (OAuth clients or SAML Service Providers) that expect Single Sign-Out and for which Single Sign-Out has not been done. If &#x60;true&#x60;, information of clients with sign-on session is cleared when ending the session. Default value is &#x60;false&#x60;.
     * @param ignoreProviderSingleSignOut Flag for ending the authenticated session also in the case that there are identity providers that expect Single Sign-Out and for which Single Sign-Out has not been done. Default value is &#x60;false&#x60;.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public endSession(ignoreConsumerSingleSignOut?: boolean, ignoreProviderSingleSignOut?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public endSession(ignoreConsumerSingleSignOut?: boolean, ignoreProviderSingleSignOut?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public endSession(ignoreConsumerSingleSignOut?: boolean, ignoreProviderSingleSignOut?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public endSession(ignoreConsumerSingleSignOut?: boolean, ignoreProviderSingleSignOut?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ignoreConsumerSingleSignOut !== undefined && ignoreConsumerSingleSignOut !== null) {
            queryParameters = queryParameters.set('ignoreConsumerSingleSignOut', <any>ignoreConsumerSingleSignOut);
        }
        if (ignoreProviderSingleSignOut !== undefined && ignoreProviderSingleSignOut !== null) {
            queryParameters = queryParameters.set('ignoreProviderSingleSignOut', <any>ignoreProviderSingleSignOut);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/sessions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starts sign-on by delegating authentication to an external identity provider
     * Redirects or POSTs to authentication endpoint of an external identity provider. The client application should do a page transition to this operation, so that the redirect or POST issued by the response of this operation can do a page transition to the external IdP.
     * @param applicationId Id of the external identity provider, as used for identifying the external identity provider application and related configuration in this system
     * @param next URL where user is directed to after sign-on. Usually this URL directs back to the same user interface that started the sign-on.
     * @param loginHint Hint to the external identity provider about the login identifier the end user might use to log in, in practice the login email.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signOnWithExternalIdentity(applicationId: string, next: string, loginHint?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public signOnWithExternalIdentity(applicationId: string, next: string, loginHint?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public signOnWithExternalIdentity(applicationId: string, next: string, loginHint?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public signOnWithExternalIdentity(applicationId: string, next: string, loginHint?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling signOnWithExternalIdentity.');
        }

        if (next === null || next === undefined) {
            throw new Error('Required parameter next was null or undefined when calling signOnWithExternalIdentity.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (next !== undefined && next !== null) {
            queryParameters = queryParameters.set('next', <any>next);
        }
        if (loginHint !== undefined && loginHint !== null) {
            queryParameters = queryParameters.set('login_hint', <any>loginHint);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/sessions/externalIdentityProviders/${encodeURIComponent(String(applicationId))}/signOn`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starts sign-out process for signing out of a connected client application
     * Redirects or POSTs to single sign-out endpoint of a client application that has a single sign-on session against this service. The application using this API should do a page transition to this operation, so that the redirect or POST issued by the response of this operation can do a page transition to the connected client application.
     * @param applicationId Id of the client application, as used for identifying the client application and related configuration in this system
     * @param next URL where user is directed to after sign-out. Usually this URL directs back to the same user interface that started the sign-out.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signOutOfClient(applicationId: string, next?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public signOutOfClient(applicationId: string, next?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public signOutOfClient(applicationId: string, next?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public signOutOfClient(applicationId: string, next?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling signOutOfClient.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (next !== undefined && next !== null) {
            queryParameters = queryParameters.set('next', <any>next);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/sessions/clients/${encodeURIComponent(String(applicationId))}/signOut`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starts sign-out process for signing out of an external identity provider
     * Redirects or POSTs to single sign-out endpoint of an external identity provider. The application using this API should do a page transition to this operation, so that the redirect or POST issued by the response of this operation can do a page transition to the external IdP.
     * @param applicationId Id of the external identity provider, as used for identifying the external identity provider application and related configuration in this system
     * @param next URL where user is directed to after sign-out. Usually this URL directs back to the same user interface that started the sign-out.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signOutOfExternalIdentity(applicationId: string, next?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public signOutOfExternalIdentity(applicationId: string, next?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public signOutOfExternalIdentity(applicationId: string, next?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public signOutOfExternalIdentity(applicationId: string, next?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling signOutOfExternalIdentity.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (next !== undefined && next !== null) {
            queryParameters = queryParameters.set('next', <any>next);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/sessions/externalIdentityProviders/${encodeURIComponent(String(applicationId))}/signOut`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
