import {Component, HostBinding, Input, OnChanges, OnInit} from '@angular/core';
import {inputFeedbackTransition} from '../animations';
import {ConfigurationService} from '../ui-configuration/configuration-service';

@Component({
  selector: '[app-form-input-feedback]',
  templateUrl: './form-input-feedback.component.html',
  styleUrls: ['./form-input-feedback.component.scss'],
  animations: [
    inputFeedbackTransition,
  ],
})
export class FormInputFeedbackComponent implements OnInit, OnChanges {

  icon: string;
  hostClass: string;
  private configuration: ConfigurationService;

  @Input() type: string;
  @Input() class: string;
  @Input() ngClass: any;
  @HostBinding('class') get whatever(): string {
    const retVal: Array<string> = [];
    if (this.hostClass) {
      retVal.push(this.hostClass);
    }
    if (this.class) {
      retVal.push(this.class);
    }
    if (this.ngClass) {
      for (const i in this.ngClass) {
        if (this.ngClass.hasOwnProperty(i) && this.ngClass[i]) {
          retVal.push(i);
        }
      }
    }
    return retVal.length ? retVal.join(' ') : undefined;
  }
  // TODO: THis may not be needed or used at all, investigate and refactor if so
  @HostBinding('@inputFeedbackTransition') trigger = '';
  @HostBinding('@.disabled') disableAnimations(): boolean {
    const enabled = this.configuration.getProperties().enableAnimations;
    // for disabling our java client lib anims append: |javafx\/
    const not_supported: boolean = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    return !enabled || not_supported;
  }

  constructor(configuration: ConfigurationService) {
    this.configuration = configuration;
  }
  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.type === 'invalid') {
      this.icon = 'icon mr-1 fa fa-fw fa-exclamation-circle';
      this.hostClass = 'feedback invalid-feedback text-right';
    } else if (this.type === 'valid') {
      this.icon = 'icon mr-1 fa fa-fw fa-check-circle';
      this.hostClass = 'feedback valid-feedback text-right';
    } else {
      this.icon = 'icon mr-1 fa fa-fw fa-info-circle';
      this.hostClass = 'feedback info-feedback small form-text text-muted text-right';
    }

  }


}
