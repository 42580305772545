import { Injectable } from '@angular/core';
import { Logger } from './logger';

@Injectable()
export class ConsoleLoggerService implements Logger {

  public debug(msg: string, ...subst: any): void {
    this.emitLogMessage('debug', msg, subst);
  }

  public debugObjs(...objs: any): void {
    this.emitLogMessageObjs('debug', objs);
  }

  public warn(msg: string, ...subst: any): void {
    this.emitLogMessage('warn', msg, subst);
  }

  public warnObjs(...objs: any): void {
    this.emitLogMessageObjs('warn', objs);
  }

  public error(msg: string, ...subst: any): void {
    this.emitLogMessage('error', msg, subst);
  }

  public errorObjs(...objs: any): void {
    this.emitLogMessageObjs('error', objs);
  }

  public info(msg: string, ...subst: any): void {
    this.emitLogMessage('info', msg, subst);
  }

  public infoObjs(...objs: any): void {
    this.emitLogMessageObjs('info', objs);
  }

  private emitLogMessage(msgType: 'debug' | 'info' | 'warn' | 'error', msg: string, subst: any[]) {
    let ffs: string = msgType;
    if (!console[ffs]) {
      ffs = 'log';
    }
    if (subst.length > 0) {
      console[ffs](msg, ...subst);
    } else {
      console[ffs](msg);
    }
  }

  private emitLogMessageObjs(msgType: 'debug' | 'info' | 'warn' | 'error', objs: any[]) {
    let ffs: string = msgType;
    if (!console[ffs]) {
      ffs = 'log';
    }
    console[msgType](...objs);
  }
}
