import {AuthenticationGuardService} from './authentication-guard.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

/**
 * This route is needed inside configuration-guard.service.ts to override routes disabled with configuration. As the AppRoutes conf
 * itself is not accessible from the guard instance, NOT_FOUND_ROUTE must be export separately to avoid circular dependencies when used.
 */
export const NOT_FOUND_ROUTE = {
  path: '**',
  component: PageNotFoundComponent,
  data: {
    animation: 'NotFound',
    validateSession: AuthenticationGuardService.always,
  },
};
