import {getApiBasePath} from './api-base-path';

let cached: any;
/**
 * Creates a function for loading the api description
 */
export function fetchApiDescription(): () => Promise<any> {
  // wrap the whole thing in a function, so that using this looks like a function call, and api is only loaded if needed.
  return async () => {
    if (!cached) {
      let response: Response;
      try {
        response = await fetch(getApiBasePath() + '/openapi');
        if (response.status !== 200) {
          throw new Error('Loading Api description from ' + getApiBasePath() + '/openapi failed');
        }
      } catch (err) {
        throw new Error('Loading Api description from ' + getApiBasePath() + '/openapi failed: ' + err);
      }
      // convert to object and store to avoid reloading if needed again.
      cached = await response.json();
    }
    return cached;
  };
}

/**
 * Creates a function for loading the api description info
 */
export function fetchApiInfo(): () => Promise<any> {
  return async () => {
      let response: Response;
      try {
        response = await fetch(getApiBasePath() + '/openapi?info');
        if (response.status !== 200) {
          throw new Error('Loading Api description info from ' + getApiBasePath() + '/openapi?info failed');
        }
      } catch (err) {
        throw new Error('Loading Api description info from ' + getApiBasePath() + '/openapi?info failed: ' + err);
      }
      return await response.json();
  };
}
