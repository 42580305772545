import {Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../AppConstants';
import {ParamsService} from '../params.service';
import {Logger} from '../../util/logger';
import {Observable} from 'rxjs';
import {
  AcceptAgreementsChallenge, AgreementAcceptance,
} from '../../gen';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';
import {ProcessingIndicatorService} from '../processing-indicator.service';
import {
  AcceptAgreementChallengeWithReceivedTime,
  flattenChallenges,
  selectAgreements,
} from '../../util/AgreementUtils';
import {FormInputComponent} from '../form-input/form-input.component';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgreementsComponent extends StandaloneComponent implements OnInit, OnDestroy {

  private destroyed = false;
  noAgreementsFound = false;
  submitFailed = false;
  agreements: AcceptAgreementChallengeWithReceivedTime[] | undefined;
  agreementsForm;
  hasTimeToRespond: boolean;
  responseTime: number;

  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private translate: TranslateService,
    private logger: Logger,
    private processingService: ProcessingIndicatorService,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
  }

  ngOnInit() {
    if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      const challs = this.appStateService.getAppState().getAuthenticationState()
        .getRemainingChallenges()
        .filter((c) => c.authenticationMethod === AppConstants.AC_AM_ACCEPT_AGREEMENTS)
        .map((c) => (c as AcceptAgreementsChallenge).userAgreementChallenges)
      ;
      selectAgreements(challs[0], { locale: this.translate.currentLang || this.translate.defaultLang })
        .then(flattenChallenges)
        .then((agrs) => {
          this.agreements = (agrs as AcceptAgreementChallengeWithReceivedTime[]);
          const controls = {};

          if (!this.agreements || this.agreements.length === 0) {
            this.noAgreementsFound = true;
          } else {
            // reset the flag and time to respond initially to 0
            this.hasTimeToRespond = true;
            this.responseTime = 0;
            for (let i = 0; i < this.agreements.length; i += 1) {
              const validators = [];
              if (this.agreements[i].required) {
                validators.push(Validators.requiredTrue);
                // only required affect the response time
                if (this.hasTimeToRespond && !this.agreements[i].responseExpectedInSeconds) {
                  // response required immediately
                  this.hasTimeToRespond = false;
                }
                if (!!this.agreements[i].responseExpectedInSeconds) {
                  // Update shortest defining response time
                  const tc = this.agreements[i].responseExpectedInSeconds * 1000 + this.agreements[i].received;
                  if (this.responseTime === 0 ||
                    tc < this.responseTime) {
                    this.responseTime = tc;
                  }
                }
              }
              controls['agreements_' + i] = new FormControl(false,
                {validators, updateOn: 'change'});
            }
            if (this.responseTime === 0) {
              this.hasTimeToRespond = false;
            }
          }
          this.agreementsForm = new FormGroup(controls);
          this.setReady();
        })
      ;
    } else {
      this.setReady();
    }
  }
  getResponseTimeAsString() {
    const format = 'short';
    const locale = this.translate.currentLang || this.translate.defaultLang;
    const formattedDate = formatDate(new Date(this.responseTime), format, locale);
    return '<strong>' + formattedDate + '</strong>';
  }
  ngOnDestroy() {
    this.destroyed = true;
  }

  getAgreementNameLink(a: AcceptAgreementChallengeWithReceivedTime) {
    if (a.location) {
      return '<a class="name" href="' + a.location + '" rel="noopener" target="_blank">' + a.name + '</a>';
    } else {
      return '<span class="name">' + a.name + '</span>';
    }
  }

  hasFormChanges() {
    return this.agreements?.map((a, i) => {
      return this.agreementsForm.get('agreements_' + i).value;
    }).find((t) => {
      return t === true;
    });
  }

  isFieldInvalid(field: string): boolean {
    if (field === 'agreements') {
      const count = this.agreements ? this.agreements.length : 0;
      if (count > 0) {
        for (let i = 0; i < count; i += 1) {
          const tmp = this.isFieldInvalid(field + '_' + i);
          if (tmp) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      return this.hasError(field) || this.isInputInvalid(field);
    }
  }

  isInputInvalid(field: string): boolean {
    if (field.startsWith('agreements_')) {
      const siblingsActive = [...Array(10).keys()]
        .map((i) => this.agreementsForm.get('agreements_' + i)?.value)
        .find((f) => f)
      ;
      return this.agreementsForm.get(field).invalid && siblingsActive;
    } else {
      return this.agreementsForm.get(field).invalid && this.agreementsForm.get(field).touched;
    }
  }

  isFieldValid(field: string): boolean {

    if (field === 'agreements') {
      const count = this.agreements ? this.agreements.length : 0;
      if (count > 0) {
        for (let i = 0; i < count; i += 1) {
          const tmp = this.isFieldValid(field + '_' + i);
          if (!tmp) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    } else {
      return !this.hasError(field) && this.isInputValid(field);
    }
  }

  isInputValid(field: string): boolean {
    if (field.startsWith('agreements_')) {
      const siblingsActive = [...Array(10).keys()]
        .map((i) => this.agreementsForm.get('agreements_' + i)?.value)
        .find((f) => f)
      ;
      return this.agreementsForm.get(field).valid && siblingsActive;
    } else {
      return this.agreementsForm.get(field).valid && this.agreementsForm.get(field).touched;
    }
  }

  hasError(field?: string, code?: string): boolean {
    // submit error handling not currently used to highlight inputs
    return false;
  }
  resolveFieldValueForIncludedField(field: string): string | boolean | undefined {
    return this.agreementsForm.get(field).value;
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputWrapperClass(): string {
    return FormInputComponent.WRAPPER_CLASS + ' checkbox-group';
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputLabelClass(): string {
    return FormInputComponent.LABEL_CLASS;
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputControlWrapperClass(): string {
    return FormInputComponent.CONTROL_WRAPPER_CLASS;
  }

  private resolvePathForSession(): string {
    let retVal = null;
    retVal = AppConstants.PATH_LOGIN;
    return retVal;
  }
  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.route,
    });
  }

  onSubmit() {
    const agrCreds: AgreementAcceptance[] = this.agreements.map((a, i) => {
      return {
        agreementId: a.agreementId,
          accept: this.resolveFieldValueForIncludedField('agreements_' + i),
          authenticationMethod: AppConstants.AC_AM_ACCEPT_AGREEMENT,
      } as AgreementAcceptance;
    });

    const handler = (response) => {
      const t = this.appStateService.getAppState().resolveNextRoute();
      if (typeof t === 'string') {
        this.processingService.show();
        window.location.href = t as string;
      } else if (t.path === AppConstants.PATH_AGREEMENTS) {
        this.router.navigate([AppConstants.PATH_PROFILE], {
          queryParams: {},
        });
      } else {
        this.router.navigate([t.path], {
          queryParams: t.queryParams,
          fragment: t.fragment,
        });
      }
    };
    const errorHandler = (response) => {
      this.submitFailed = true;
    };
    this.appStateService.addAuthentications(agrCreds, false).subscribe(handler, errorHandler);
  }
  later() {
    const c: AgreementAcceptance = {
      authenticationMethod: AppConstants.AC_AM_ACCEPT_AGREEMENTS,
    } as AgreementAcceptance;
    // add a fake credential to allow for the user to proceed even though the re are still challenges left to complete.
    this.appStateService.getAppState().getAuthenticationState().addExistingAuthenticationChallengeByCompletedCredential(c);
    this.close();
  }
  close() {
    if (!this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      const qp = {
      };
      this.router.navigate([this.resolvePathForSession()], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: qp,
      });
    } else {
      const t = this.appStateService.getAppState().resolveNextRoute();
      if (typeof t === 'string') {
        this.processingService.show();
        window.location.href = t as string;
      } else if (t.path === AppConstants.PATH_AGREEMENTS) {
        this.router.navigate([AppConstants.PATH_PROFILE], {
          queryParams: {},
        });
      } else {
        this.router.navigate([t.path], {
          queryParams: t.queryParams,
          fragment: t.fragment,
        });
      }
    }
  }

  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('agreements.window-title');
  }
}
