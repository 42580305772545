import {Component, ElementRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';

const IncludeInJSONOutput = ['title', 'version'];


@Component({
  selector: 'app-invalid-api',
  templateUrl: './invalid-api.component.html',
  styleUrls: ['./invalid-api.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvalidApiComponent extends StandaloneComponent implements OnInit {

  @Input() apis: { required: any, found: any }|undefined;
  requiredInfo: string|undefined;
  foundInfo: string|undefined;
  showDetails = false;
  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private translate: TranslateService,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
  }

  ngOnInit() {
    super.init();
    if (this.apis) {
      this.requiredInfo = JSON.stringify(this.apis.required, IncludeInJSONOutput, 2);
      this.foundInfo = JSON.stringify(this.apis.found, IncludeInJSONOutput, 2);
    } else {
      this.requiredInfo = undefined;
      this.foundInfo = undefined;
    }
  }
  public toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('invalid-api.window-title');
  }
}
