import {Component, ElementRef, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { User } from '../../gen/model/user';
import { Logger } from '../../util/logger';
import { StandaloneComponent } from '../standalone.component';
import {CountriesService} from '../countries-service';
import {Observable, Subscription} from 'rxjs';
import {AppComponent} from '../app.component';
import {TranslateService} from '@ngx-translate/core';
import {AppConstants} from '../AppConstants';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {SsoUIField} from '../ui-configuration/ssoui-configuration';
import {AppStateService, AuthenticationState} from '../app-state.service';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {
  AcceptAgreementChallengeWithReceivedTime, listUsersAgreementsWithReceivedTime
} from '../../util/AgreementUtils';
import {AgreementService} from '../../gen';
import {formatDate} from '@angular/common';
const cloneDeep = require('lodash.clonedeep');


@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewProfileComponent extends StandaloneComponent implements OnInit {

  personalFields: SsoUIField[];
  contactFields: SsoUIField[];
  loginFields: SsoUIField[];
  hasEditableFields: boolean;
  hasTOTP: boolean;
  notification: { key: string, data?: any } | undefined;
  userAgreements: AcceptAgreementChallengeWithReceivedTime[] | undefined;
  noAgreementsFound: boolean;
  agreementLoadingFailed: boolean;
  agreementLoadingFailedDismissed: boolean;

  profile: User;
  hasDelegatedAuthentication = false;
  private navigationEndSubscription: Subscription;
  constructor(
    @Inject('AgreementApi') protected agreementApi: AgreementService,
    protected appStateService: AppStateService,
    route: ActivatedRoute,
    router: Router,
    protected countries: CountriesService,
    protected logger: Logger,
    protected translate: TranslateService,
    private alertHandler: AlertHandler,
    hostElement: ElementRef,
    configuration: ConfigurationService) {
  super(route, router, hostElement, configuration);
    this.focusSelectorPrefix = '.card-body';
    this.personalFields = cloneDeep(this.configuration.getProperties().profilePersonalFields);
    this.contactFields = cloneDeep(this.configuration.getProperties().profileContactFields);
    this.loginFields = cloneDeep(this.configuration.getProperties().profileLoginFields);
    if (this.appStateService.getAppState().hasNotification(AppConstants.SN_LOGIN_EMAIL_VALIDATED)) {
      this.showNotification(AppConstants.SN_LOGIN_EMAIL_VALIDATED);
    }
  }


  isAccepted(t: AcceptAgreementChallengeWithReceivedTime): boolean {
    const retVal = t && t.accepted && t.accepted > 0;
    return retVal;
  }
  isRejected(t: AcceptAgreementChallengeWithReceivedTime): boolean {
    const retVal = t && t.declined && t.declined > 0;
    return retVal;
  }

  getAgreementResponseTimeAsString(a: AcceptAgreementChallengeWithReceivedTime) {
    const format = 'longDate';
    const locale = this.translate.currentLang || this.translate.defaultLang;
    const formattedDate = formatDate(new Date(a.accepted || a.declined), format, locale);
    return formattedDate;
  }

  showNotification(key: string, data?: any) {
    this.notification = { key };
    if (!!data) {
      this.notification.data = { ...data };
    }
  }
  hideNotification() {
    if (this.notification && this.notification.key === AppConstants.SN_LOGIN_EMAIL_VALIDATED) {
      this.appStateService.getAppState().removeNotification(AppConstants.SN_LOGIN_EMAIL_VALIDATED);
    }
    this.notification = undefined;
  }
  onProfileLoaded(user: User) {
    this.profile = user;
  }
  ngOnInit() {
    super.init();
    const authState: AuthenticationState = this.appStateService.getAppState().getAuthenticationState();
    if (authState &&
        authState.getExistingAuthentications() &&
        authState.getExistingAuthentications().length) {
        if (!authState.getExistingAuthentications().find((val) => val.authenticationMethod === AppConstants.AC_AM_PASSWORD)) {
          // hide change password when there is no password
          this.loginFields = this.loginFields.filter((val) => val.key !== 'password');
        }
        if (!!authState.getExistingAuthentications().find((val) => val.authenticationMethod === AppConstants.AC_AM_TOTP)) {
          // hide totp when there is no totp, but don't remove from fields as it's still needed in edit
          this.hasTOTP = true;
        } else {
          this.hasTOTP = false;
        }
        if (authState.getExistingAuthentications().find((val) => val.authenticationMethod === AppConstants.AC_AM_DELEGATED)) {
          this.hasDelegatedAuthentication = true;
          // hide totp and make all fields readonly when there is delegated auth
          this.loginFields = this.loginFields.filter((val) => val.key !== 'totp').map((v) => ({ ...v, readonly: true }));
          this.personalFields = this.personalFields.map((v) => ({ ...v, readonly: true }));
          this.contactFields = this.contactFields.map((v) => ({ ...v, readonly: true }));
        }
    } else {
      throw new Error('Not authorized');
    }
    if (this.personalFields.filter((v) => !v.readonly).length > 0 ||
      this.contactFields.filter((v) => !v.readonly).length > 0 ||
      this.loginFields.filter((v) => !v.readonly).length > 0) {
      this.hasEditableFields = true;
    } else {
      this.hasEditableFields = false;
    }
    this.onProfileLoaded(this.appStateService.getAppState().getAuthenticationState().getProfile());

    this.navigationEndSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, this.focusSelectorPrefix);
      }
    });
    this.agreementLoadingFailedDismissed = false;
    listUsersAgreementsWithReceivedTime(
      this.agreementApi
    ).then((agr) => {
      if (!!agr) {
        this.userAgreements = agr.agreements;
        this.agreementLoadingFailed = false;
        if (this.userAgreements && this.userAgreements.length) {
          this.noAgreementsFound = false;
        } else {
          this.noAgreementsFound = true;
        }
      } else {
        this.noAgreementsFound = true;
      }
    }, (err) => {
      this.agreementLoadingFailed = true;
    });
  }
  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.route,
    });
  }

  resolveDisplayName(): string|any {
    // we should never be here with a partial session
    return AppComponent.resolveUserName(this.profile, this.translate, this.configuration);
  }

  resolveCountryName(code: string): Observable<string> {
    return this.countries.getCountryName(code);
  }

  includes(list: SsoUIField[], value: string): boolean {
    return list.findIndex((val) => val.key === value) >= 0;
  }
  /**
   * Adds &#x200b; (wordbreak) char for each non alphanumeric character
   */
  addWordBreaks(val: string): string {
    return val ? val.replace(/(\W)/g, '<wbr />$1') : val;
  }

  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('view-profile.window-title');
  }
}
