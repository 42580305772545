import {Logger} from '../util/logger';
import { ErrorHandler, Inject, Injectable } from '@angular/core';

@Injectable()
export class LoggerErrorHandler implements ErrorHandler {
  constructor(@Inject(Logger) private logger: Logger) {}
  handleError(error) {
    // do something with the exception
    this.logger.error(error);
  }
}
