import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-bs-sample',
  templateUrl: './bs-sample.component.html',
  styleUrls: ['./bs-sample.component.scss']
})
export class BsSampleComponent implements OnInit {
  @HostBinding('class') hostClass = 'order-2';
  constructor() { }

  ngOnInit() {
  }

}
