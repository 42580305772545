import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { of, throwError, Observable } from 'rxjs';

import { AuthenticationChallenge } from '../gen/model/authenticationChallenge';

export default class ResponseUtil {
  public static handleAuthenticationChallengeResponse(errorResponse: HttpErrorResponse): Observable<HttpErrorResponse> {
    return errorResponse && errorResponse.status === 401 ? of(errorResponse) : throwError(errorResponse);
  }

  public static readChallenges(response: HttpResponseBase): any {
    const retValue = {
      required: [] as AuthenticationChallenge[],
      optional: [] as AuthenticationChallenge[],
    };
    const wwwAuthenticateHeaders = response.headers.getAll('WWW-Authenticate');
    if (wwwAuthenticateHeaders != null) {
      let t = [];
      for (let  i = 0; i < wwwAuthenticateHeaders.length; i += 1) {
        t = t.concat(wwwAuthenticateHeaders[i].split(','));
      }
      t.map(header => {
        const challengeMatch = header.match('10Duke-Challenge.+challenge="([^"]+)"');
        if (challengeMatch) {
          const decodedChallenge = decodeURIComponent(challengeMatch[1]?.replaceAll('+', '%20'));
          const challengeObj: AuthenticationChallenge = JSON.parse(decodedChallenge) as AuthenticationChallenge;
          if (challengeObj.required === true) {
            retValue.required.push(challengeObj);
          } else {
            retValue.optional.push(challengeObj);
          }
        }
      });
    }
    return retValue;
  }
}
