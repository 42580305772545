import {Component, ElementRef, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../gen/api/password.service';
import {ChangePasswordRequest} from '../../gen/model/changePasswordRequest';
import {User} from '../../gen/model/user';
import {AppConstants} from '../AppConstants';
import {StandaloneComponent} from '../standalone.component';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition} from '../animations';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition
  ],
})
export class ChangePasswordComponent extends StandaloneComponent implements OnInit {

  profile: User;

  changePasswordForm: FormGroup;

  submitFailed = false;
  invalidOldPWD = false;
  invalidNewPWD = false;

  constructor(@Inject('PasswordApi') private passwordApi: PasswordService,
              private appStateService: AppStateService,
              private alertHandler: AlertHandler,
              private translate: TranslateService,
              route: ActivatedRoute,
              router: Router,
              hostElement: ElementRef,
              configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(configuration.getProperties().passwordValidation)]),
      confirmNewPassword: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    super.init();
    this.validatePasswords = this.validatePasswords.bind(this);
    this.changePasswordForm.setValidators([this.validatePasswords]);
  }
  getProfile(): User {
    return this.appStateService.getAppState().getAuthenticationState().getProfile();
  }
  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.route,
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  isInputInvalid(field: AbstractControl): boolean {
    if (field === this.changePasswordForm.get('confirmNewPassword')) {
      return (field.invalid ||
        (this.changePasswordForm.invalid && this.changePasswordForm.errors && this.changePasswordForm.errors.mustMatch)) && field.touched;

    } else {
      return field.invalid && field.touched;
    }
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  isInputValid(field: AbstractControl): boolean {
    if (field === this.changePasswordForm.get('confirmNewPassword')) {
      return field.valid
        && (this.changePasswordForm.valid || !this.changePasswordForm.errors || !this.changePasswordForm.errors.mustMatch)
        && field.touched;
    } else {
      return field.valid && field.touched;
    }
  }

  hasError(field?: AbstractControl, code?: string): boolean {
    let retVal = false;
    if (!field) {
      retVal = this.submitFailed;
    } else if (field === this.changePasswordForm.get('currentPassword')) {
      retVal = this.invalidOldPWD;
    } else if (field === this.changePasswordForm.get('newPassword')) {
      retVal = this.invalidNewPWD;
    }
    return retVal;
  }

  submit() {
    this.submitFailed = false;
    this.invalidOldPWD = false;
    this.invalidNewPWD = false;
    const obj = {
      currentPassword: this.changePasswordForm.get('currentPassword').value,
      newPassword: this.changePasswordForm.get('newPassword').value,
    } as ChangePasswordRequest;
    this.passwordApi.changePassword(obj, 'response').subscribe((response) => {
      this.router.navigate([AppConstants.PATH_EDIT_PROFILE], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        fragment: 'login',
      });
    }, (err) => {
      this.submitFailed = true;
      if (err && err.error && err.error.error === 'invalid_old_password') {
        this.invalidOldPWD = true;
      } else if (err && err.error && err.error.error === 'password_quality_not_satisfied') {
        this.invalidNewPWD = true;
      }
    });
  }

  private validatePasswords(control: AbstractControl): ValidationErrors | null {
    const pwField = this.changePasswordForm.get('newPassword');
    const cpwField = this.changePasswordForm.get('confirmNewPassword');
    return cpwField.value === pwField.value
      ? null
      : {'mustMatch': true};
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('change-password.window-title');
  }

}
