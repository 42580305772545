import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * App wide in processing state
 */
export class ProcessingIndicatorService {
  private statusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public show() {
    this.statusSubject.next(true);
  }
  public hide() {
    this.statusSubject.next(false);
  }
  public isVisible(): boolean {
    return this.statusSubject.getValue();
  }
  public subscribe(next?: (visible: boolean) => void ): Subscription {
    return this.statusSubject.subscribe(next);
  }
}
