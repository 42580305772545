import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ParamsService} from '../params.service';
import {AppConstants} from '../AppConstants';
import {DomSanitizer} from '@angular/platform-browser';
import {Logger} from '../../util/logger';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';
import {ProcessingIndicatorService} from '../processing-indicator.service';


@Component({
  selector: 'app-connect-device',
  templateUrl: './connect-device.component.html',
  styleUrls: ['./connect-device.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConnectDeviceComponent extends StandaloneComponent implements OnInit {

  url: string|undefined;
  urlRejected = false;
  matchedPattern: string|undefined;

  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private translate: TranslateService,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private sanitizer: DomSanitizer,
    private logger: Logger,
    private processingService: ProcessingIndicatorService,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
  }

  ngOnInit() {

    this.paramsService.getParam(AppConstants.QP_CONNECT_HANDLER).then((result) => {
      this.url = result;
      this.matchedPattern = undefined;
      if (!!this.url) {
        const regex = this.configuration.getProperties().allowedDeviceRedirectPatterns;
        if (!regex || regex.length === 0) {
          this.urlRejected = true;
        } else {
          let allowed = false;
          regex.forEach((r) => {
            if (!allowed) {
              const re = new RegExp(r);
              allowed = re.test(this.url);
              if (allowed) {
                this.matchedPattern = r;
              }
            }
          });
          this.urlRejected = !allowed;
        }
      } else {
        this.urlRejected = false;
      }
    });
  }
  continue() {
    if (!this.urlRejected && !!this.url) {
      window.location.href = this.url;
    } else {
      // this should not happen as the button triggering this should not be accessible without valid data
      this.cancel();
    }
  }
  cancel() {
    if (!this.appStateService.getAppState().getAuthenticationState().hasSession() ||
      !this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      this.router.navigate([AppConstants.PATH_LOGIN], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([AppConstants.PATH_PROFILE], {
        relativeTo: this.route,
      });
    }
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('connect-device.window-title');
  }
}
