import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private localeParam: string = null;
  private isAvailable = false;
  constructor() { }

  // noinspection JSUnusedGlobalSymbols
  getLocaleParam(): string {
    return this.localeParam;
  }

  setLocaleParam(value: string) {
    this.localeParam = value;
  }

  getIsAvailable(): boolean {
    return this.isAvailable;
  }

  setIsAvailable(value: boolean) {
    this.isAvailable = value;
  }
}
