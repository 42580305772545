import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AppStateService, AuthenticationState, ParamsForRouteNav} from './app-state.service';
import {getAppBaseHref} from '../util/locationUtil';
import {AppConstants} from './AppConstants';
import {ProcessingIndicatorService} from './processing-indicator.service';

@Injectable()
export class AuthenticationGuardService implements CanActivate {
  static isFullyAuthenticatedWithOptionalsHandled(auths: AuthenticationState) {
    return AuthenticationGuardService.isFullyAuthenticated(auths) && auths.areOptionalsHandled();
  }
  static isFullyAuthenticated(auths: AuthenticationState) {
    return AuthenticationGuardService.hasPartialSession(auths) && auths.isFullyAuthenticated();
  }
  static hasPartialSession(auths: AuthenticationState) {
    return auths && auths.hasSession();
  }
  static hasOnlyPartialSession(auths: AuthenticationState) {
    return AuthenticationGuardService.hasPartialSession(auths) && !auths.isFullyAuthenticated();
  }
  static hasNoSession(auths: AuthenticationState) {
    return auths && !auths.hasSession();
  }
  static never(auths: AuthenticationState) {
    return false;
  }
  static always(auths: AuthenticationState) {
    return true;
  }

  constructor(
    private appStateService: AppStateService,
    private processingService: ProcessingIndicatorService,
    private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve: any) => {
      const subscription = this.appStateService.subscribeInitState((aps: AppStateService) => {
        const auth = aps.getAppState().getAuthenticationState();
        if (!!auth) {
          if (subscription) {
            subscription.unsubscribe();
          }
          const validateSession = route.routeConfig.data.validateSession ||
            AuthenticationGuardService.isFullyAuthenticatedWithOptionalsHandled;
          const useAsNext = route.routeConfig.data.showAfterValidateSession !== undefined ?
            route.routeConfig.data.showAfterValidateSession : true;
          const result = validateSession(auth, route);
          if (!result) {
            const newNextWrapper: {[key: string]: string} = {};
            if (useAsNext) {
              // we match previous functionality were params are kept, but we add the requested url as next.
              newNextWrapper.next = '/' +
                window.location.href.replace(
                  new RegExp('^' + new URL(window.location.href).origin + getAppBaseHref()), '')
              ;
            }
            const nav: ParamsForRouteNav|string|undefined = this.appStateService.getAppState().resolveNextRoute(
              !useAsNext ? ['*'] : undefined);
            if (typeof nav === 'string') {
              this.processingService.show();
              window.location.href = nav as string;
            } else if (nav) {
              // overrides existing next if any, original next still included in this wrapped next, and will thus be eventually handled
              this.router.navigate([nav.path], {
                queryParams: { ...nav.queryParams, ...newNextWrapper },
                fragment: nav.fragment,
                replaceUrl: true,
              });
            }
          }
          resolve(result);
        }
      });
    });
  }
}
