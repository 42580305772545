import {DukeAlertComponent} from './duke-alert/duke-alert.component';
import {Injectable} from '@angular/core';

@Injectable()
export abstract class AlertHandler {
  // noinspection JSUnusedGlobalSymbols
  openAlerts: DukeAlertComponent[];
  // noinspection JSUnusedGlobalSymbols
  public abstract onOpen(comp: DukeAlertComponent): void;
  // noinspection JSUnusedGlobalSymbols
  public abstract onClose(comp: DukeAlertComponent): void;
}

@Injectable()
export class NoConcurrentAlertsHandler implements AlertHandler {
  openAlerts: DukeAlertComponent[] = [];
  constructor() {}

  onOpen(comp: DukeAlertComponent) {
    this.openAlerts = this.openAlerts.filter((v) => v !== comp);
    for (let i = 0; i < this.openAlerts.length; i += 1) {
      this.openAlerts[i].onAlertClose();
    }
    this.openAlerts.push(comp);
  }
  onClose(comp: DukeAlertComponent) {
    this.openAlerts = this.openAlerts.filter((v) => v !== comp);
  }
}
