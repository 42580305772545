import {Observable, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {ConfigurationModel, ConfigurationService} from './configuration-service';
import {NOT_FOUND_ROUTE} from '../NotFoundRoute';

@Injectable()
export class ConfigurationGuardService implements CanActivate {

  constructor(
    private configuration: ConfigurationService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve: any) => {
      const cb = (props: ConfigurationModel) => {
        const blockAccess = route.routeConfig?.data?.blockAccess || ((p: any) => false);
        if (blockAccess(props)) {
          // replace the config with that of PageNotFoundComponent
          route.routeConfig.component = NOT_FOUND_ROUTE.component;
          route.routeConfig.data = NOT_FOUND_ROUTE.data;
        }
        resolve(true);
      };
      if (this.configuration.isReady) {
        cb(this.configuration.getProperties());
      } else {
        const sub: Subscription = this.configuration.whenReady().subscribe((v) => {
          if (v === true) {
            sub.unsubscribe();
            cb(this.configuration.getProperties());
          }
        });
      }
    });
  }
}
