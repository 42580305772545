<div
  class="card standalone fade authenticate-device-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'authenticate-device.title'|translate}}
    </h1>
  </div>
  <ng-container *ngIf="processCompleted !== undefined">
    <div class="card-body">
      <app-duke-alert
        [attr.data-test-complete]="processCompleted"
        type="success"
        alertClass="mb-0"
        *ngIf="!completionDismissed"
        [dismissible]="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); closeCompleted();"
      >
        <p
          *ngIf="processCompleted === 'accept'"
        >
          {{'authenticate-device.accepted-copy'|translate}}
        </p>
        <p
          *ngIf="processCompleted === 'reject'"
        >
          {{'authenticate-device.rejected-copy'|translate}}
        </p>
        <p
          *ngIf="processCompleted === 'cancel'"
        >
          {{'authenticate-device.cancelled-copy'|translate}}
        </p>
      </app-duke-alert>
      <p class="text-center">
        {{'authenticate-device.completed-copy'|translate}}
      </p>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          data-test-signout
          class="btn btn-primary"
          type="button"
          (click)="signout()"
        >
          {{'authenticate-device.signout-label'|translate}}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="processCompleted === undefined">
    <div class="card-body pb-0"
         *ngIf="!showAuthenticateDeviceForm()"
    >
      <app-duke-alert
        type="info"
        alertClass="mb-0"
      >
        <p>
          {{'authenticate-device.info-copy'|translate}}
        </p>
      </app-duke-alert>
      <hr />
    </div>
    <app-duke-alert
      type="danger"
      *ngIf="authenticateDeviceFailed"
      data-test-authenticate-device-failed
      dismissible="true"
      (open)="alertHandler.onOpen($event);"
      (close)="alertHandler.onClose($event); authenticateDeviceFailed = false;"
    >
      <p class="mb-0" *ngIf="!tooManyAttemptsError">
        {{'authenticate-device.authenticate-device-failed-notification'|translate}}
      </p>
      <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'authenticate-device.submit-error.message.tooManyAttempts' | translate"></p>
      <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError  && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'authenticate-device.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
      <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'authenticate-device.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>

    </app-duke-alert>
    <app-duke-alert
      type="danger"
      *ngIf="queryDeviceFailed"
      data-test-query-device-failed
      [dismissible]="true"
      (open)="alertHandler.onOpen($event);"
      (close)="alertHandler.onClose($event); queryDeviceFailed = false;"
    >
      <p class="mb-0" *ngIf="!tooManyAttemptsError">
        {{'authenticate-device.query-device-failed-notification'|translate}}
      </p>
      <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'authenticate-device.submit-error.message.tooManyAttempts' | translate"></p>
      <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError  && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'authenticate-device.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
      <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'authenticate-device.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>

    </app-duke-alert>

    <form
      [formGroup]="authenticateDeviceForm"
      (ngSubmit)="authenticateDevice($event)"
    >
      <div [class]="'card-body' + (!showAuthenticateDeviceForm() ? ' pt-0' : '')">
        <app-duke-alert
          type="info"
          alertClass="mb-0"
          *ngIf="showAuthenticateDeviceForm()"
          data-test-device-info
        >
          <p class="mb-0" [innerHTML]="'authenticate-device.device-info'|translate: {
          name: '<strong>' + device.applicationName + '</strong>',
          scope: '<strong>' + device.requestedScope + '</strong>',
          code: '<strong>' + authenticateDeviceForm.get('userCode').value + '</strong>'}"></p>
        </app-duke-alert>
        <div
          app-form-input
          field="userCode"
          size="lg"
          [class]="showAuthenticateDeviceForm() ? 'd-none' : undefined"
          [readonly]="showAuthenticateDeviceForm()"
          label="{{'authenticate-device.code-label'|translate}}"
          [isInvalid]="isFieldInvalid(authenticateDeviceForm.get('userCode'))"
          [isValid]="isFieldValid(authenticateDeviceForm.get('userCode'))"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid(authenticateDeviceForm.get('userCode'))"
          >{{'authenticate-device.invalid-code'|translate}}</div>
        </div>
      </div>
      <div class="card-footer"
           *ngIf="!showAuthenticateDeviceForm()">
        <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="queryDeviceFailed"
              [@cardFooterFeedbackTransition]
              [@.disabled]="disableAnimations()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>{{'authenticate-device.submit-error.notification.query-failed' | translate}}</span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'authenticate-device.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }"></span>
              </ng-container>
            </span>

            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!queryDeviceFailed && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span [innerHTML] = "'authenticate-device.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
          <button
            data-test-query-device
            class="btn btn-success"
            type="submit"
            [disabled]="!authenticateDeviceForm.valid || hasTooManyAttemptsTimeout()"
          >
            {{'authenticate-device.query-label'|translate}}
          </button>
          <button type="button" class="btn btn-secondary mr-auto order-3"
             (click)="cancel()"
          >{{'authenticate-device.cancel-label'|translate}}</button>
        </div>
      </div>
      <div class="card-footer"
           *ngIf="showAuthenticateDeviceForm()"
      >
        <div class="d-flex align-items-center flex-row-reverse">
          <span
            class="text-danger order-2 mr-2 text-right"
            *ngIf="authenticateDeviceFailed"
            [@cardFooterFeedbackTransition]
            [@.disabled]="disableAnimations()"
            data-test-submit-failed-notification
          >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>{{'authenticate-device.submit-error.notification.activation-failed' | translate}}</span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'authenticate-device.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }"></span>
              </ng-container>
            </span>

          <span
            class="text-muted order-2 mr-2 text-right"
            *ngIf="!authenticateDeviceFailed && hasTooManyAttemptsTimeout()"
          >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span [innerHTML] = "'authenticate-device.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
          <button
            data-test-authenticate-device
            class="btn btn-success"
            type="submit"
            name="accept"
            value="true"
            [disabled]="!authenticateDeviceForm.valid"
          >
            {{'authenticate-device.accept-label'|translate}}
          </button>
          <button
            data-test-reject-device
            class="btn btn-danger mr-auto order-3"
            type="submit"
            name="accept"
            value="false"
            *ngIf="authenticateDeviceForm.valid"
          >
            {{'authenticate-device.reject-label'|translate}}
          </button>
          <button type="button" class="btn btn-secondary mr-auto order-3"
             *ngIf="!authenticateDeviceForm.valid"
             (click)="cancel()"
          >{{'authenticate-device.cancel-label'|translate}}</button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
