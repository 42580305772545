<div
  class="card standalone fade manage-agreements-component"
  [ngClass]="{
    'show': isReady(),
    'error': loadingFailed
  }"
>
  <ng-container
    *ngIf="noAgreementsFound || loadingFailed"
  >
    <h1 class="card-header text-center"
        *ngIf="loadingFailed"
    >
      <span class="icon d-block mx-auto fa fa-fw fa-2x fa-exclamation-triangle mb-2"></span>
      {{'manage-agreements.loading-error.title'|translate}}
    </h1>
    <h1 class="card-header text-center"
        *ngIf="noAgreementsFound"
    >
      {{'manage-agreements.title'|translate}}
    </h1>
    <div class="card-body">
      <p
        class="mb-0 text-center"
        data-test-load-failed="true"
        *ngIf="loadingFailed"
      >
        {{'manage-agreements.loading-error.copy'|translate}}
      </p>
      <app-duke-alert
        type="danger"
        *ngIf="noAgreementsFound"
        alertClass="mb-0 static"
        data-test-no-agreements="true"
      >
        <p class="mb-0">
          {{'manage-agreements.no-agreements-found.copy'|translate}}
        </p>
      </app-duke-alert>
    </div>
    <div class="card-footer text-right">
      <button
        type="button"
        class="btn btn-secondary"
        [ngClass]="{
          'btn-block': loadingFailed,
          'btn-lg': loadingFailed
        }"
        data-test-continue-button="true"
        (click)="close()"
      >
        {{'manage-agreements.close-button.label'|translate}}
      </button>

    </div>
  </ng-container>
  <form
    *ngIf="!noAgreementsFound && !loadingFailed && !!agreementsForm"
    [formGroup]="agreementsForm"
    (ngSubmit)="onSubmit()"
  >
    <h1 class="card-header text-center">
      {{'manage-agreements.title'|translate}}
    </h1>
    <div class="card-body">
      <app-duke-alert
        type="info"
        alertClass="static"
      >
        <p class="mb-0">
          {{'manage-agreements.info'|translate}}
        </p>
      </app-duke-alert>
      <app-duke-alert
        type="danger"
        *ngIf="submitFailed"
        data-test-accept-failed="true"
      >
        <p class="mb-0">
          {{'manage-agreements.agreement-submit-failed'|translate}}
        </p>
      </app-duke-alert>

      <ng-container *ngIf="userAgreements && userAgreements.length && !submitFailed && hasRequired()">
        <h5
          placement="top-left auto"
          ngbTooltip="{{'manage-agreements.required-tooltip'|translate}}"
        >
          {{'manage-agreements.required-title' | translate}}
          <span class="ml-1 fa fa-info-circle icon text-info"></span>
        </h5>
        <div [class]="resolveFormInputWrapperClass()"
             [ngClass]="{
                      'is-invalid': isFieldInvalid('agreements'),
                      'is-valid': isFieldValid('agreements')
                    }">
        <ng-container *ngFor="let agreement of userAgreements; let ind = index;">
            <div
                 *ngIf="agreement.required"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  [id]="'agreements_' + ind"
                  [name]="'agreements_' + ind"
                  [formControlName]="'agreements_' + ind"
                  class="custom-control-input"
                  (click)="agreementsForm.get('agreements_' + ind).markAsTouched()"
                  (keypress)="agreementsForm.get('agreements_' + ind).markAsTouched()"
                  [disabled]="isDisabled(agreement)"
                  [ngClass]="{
                      'is-invalid': isFieldInvalid('agreements_' + ind),
                      'is-valid': isFieldValid('agreements_' + ind)
                    }"
                />
                <label
                  class="custom-control-label"
                  [for]="'agreements_' + ind"
                >
                  <span class="agreement-label" [innerHTML] = "'manage-agreements.agreement-label' | translate: { name: getAgreementNameLink(agreement) }" ></span>
                  <em class="agreement-accepted ml-2 small"
                      *ngIf="isAccepted(agreement)"
                      [innerHTML]="'manage-agreements.accepted-copy' | translate: { date: getAgreementResponseTimeAsString(agreement) }"></em>
                  <em class="agreement-rejected ml-2 small"
                      *ngIf="isRejected(agreement)"
                      [innerHTML]="'manage-agreements.rejected-copy' | translate: { date: getAgreementResponseTimeAsString(agreement) }"></em>
                  <em class="agreement-required-soon ml-2 small"
                      *ngIf="!isAccepted(agreement) && !isRejected(agreement) && agreement.responseExpectedInSeconds > 0"
                      [innerHTML]="'manage-agreements.required-copy' | translate: { date: getAgreementResponseTimeAsString(agreement) }"></em>

                </label>
              </div>
            </div>
        </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="userAgreements && userAgreements.length && !submitFailed && hasOptional()">
        <h5>
          {{'manage-agreements.optional-title' | translate}}
        </h5>
        <div [class]="resolveFormInputWrapperClass() + ' mb-0'"
             [ngClass]="{
                      'is-invalid': isFieldInvalid('agreements'),
                      'is-valid': isFieldValid('agreements')
                    }">
          <ng-container *ngFor="let agreement of userAgreements; let ind = index;">
            <div [class]="resolveFormInputControlWrapperClass()"
                 *ngIf="!agreement.required"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  [id]="'agreements_' + ind"
                  [name]="'agreements_' + ind"
                  [formControlName]="'agreements_' + ind"
                  class="custom-control-input"
                  (click)="agreementsForm.get('agreements_' + ind).markAsTouched()"
                  (keypress)="agreementsForm.get('agreements_' + ind).markAsTouched()"
                  [ngClass]="{
                      'is-invalid': isFieldInvalid('agreements_' + ind),
                      'is-valid': isFieldValid('agreements_' + ind)
                    }"
                />
                <label
                  class="custom-control-label"
                  [for]="'agreements_' + ind"
                >
                  <a *ngIf="agreement.location" href="{{agreement.location}}" rel="noopener" target="_blank">{{agreement.name}}</a>
                  <span *ngIf="!agreement.location">
                    {{agreement.name}}
                  </span>
                  <em class="ml-2 small"
                      *ngIf="agreement && agreement.accepted"
                      [innerHTML]="'manage-agreements.accepted-copy' | translate: { date: getAgreementResponseTimeAsString(agreement) }"></em>
                  <em class="ml-2 small"
                      *ngIf="agreement && agreement.declined"
                      [innerHTML]="'manage-agreements.rejected-copy' | translate: { date: getAgreementResponseTimeAsString(agreement) }"></em>
                </label>
              </div>
            </div>
          </ng-container>
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('agreements') && hasFormChanges()"
          >{{'manage-agreements.error-message.required' | translate}}</div>
        </div>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
          <button
            class="btn btn-success"
            type="submit"
            [disabled]="!agreementsForm || !agreementsForm.valid || !hasFormChanges()"
            data-test-submit-button="true"
          >
            {{'manage-agreements.apply-button.label'|translate}}
          </button>
        <button
          type="button"
          class="btn btn-secondary order-3 mr-auto"
          data-test-continue-button="true"
          (click)="close()"
        >
          {{'manage-agreements.close-button.label'|translate}}
        </button>
      </div>
    </div>
  </form>
</div>
