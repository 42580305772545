import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {getOriginalBaseHref, getResolvedPathLocale} from './locationUtil';
import {LocaleService} from '../app/locale.service';
import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';

/**
 * Imports locale data for exact 2 part locale match and 1 part fallback
 */
export function importLocaleData(angularLocale: Intl.Locale[]) {
  // Angular has made managing localisation super easy and simple.
  // The locale-data must be registered first, before we attempt to use the locale or using the locale will fail.
  // But as our supported locales are dynamic, we don't really know which locales are supported before we attempt use them.
  // So we must first we attempt to lazy import data for the exact requested locale, and then regardless of fail/success
  // lazy import the fallback language data to ensure that there is required locale data avialable if we need to attempt to use the
  // fallback.
  const handleFallback = () => {
    return new Promise<void>((resolveFB, rejectFB) => {
      if (angularLocale.length > 1) {
        // drop first term and try again
        importLocaleData(angularLocale.slice(1))
          .then(() => {
            resolveFB();
          })
          .catch((err) => {
            rejectFB();
          });
      } else {
        resolveFB();
      }
    });
  };

  return new Promise<void>((resolve, reject) => {
    const importPath = angularLocale[0].toString();
    import(
      /* webpackChunkName: "locale-data/[request]" */
      `@/../node_modules/@angular/common/locales/${importPath}.mjs`)
      .then((lang) => {
        registerLocaleData(lang.default);
        handleFallback().then(resolve).catch(reject);
      })
      .catch((err) => {
        if (angularLocale.length > 1) {
          handleFallback().then(resolve).catch(reject);
        } else {
          reject();
        }
      })
    ;
  });
}

class CustomTranslateHttpLoader extends TranslateHttpLoader {
  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix);
  }
  getTranslation(lang) {
    let l = lang;
    if (lang === 'nb') {
      l = 'no';
    }
    return super.getTranslation(l.toLowerCase());
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, getOriginalBaseHref() + 'assets/i18n/');
}

/**
 * Attempts to set multipart locale, if not supported drops last part and tries again until all parts are handled
 */
function handleLocale(localeService: LocaleService, translate: TranslateService, angularLocale: Intl.Locale[]) {
  return new Promise<any>((resolve: any) => {
    // try using requested locale
    translate.use(angularLocale[0].toString()).subscribe(res => {
      // success, all done.
      localeService.setIsAvailable(true);
      resolve(null);
    }, err => {
      // requested locale not supported, check if there are fallbacks and try with next
      if (angularLocale.length > 1) {
          handleLocale(localeService, translate, angularLocale.slice(1)).then(() => {
            resolve(null);
          });
      } else {
        // failed, locale not supported. Leave in path for future support and show notification
        localeService.setIsAvailable(false);
        resolve(null);
      }
    });
  });
}
function resolveLocaleFallbackListForAngular(loc: Intl.Locale): Intl.Locale[] {
  const retVal = [];
  const lang = loc.language === 'no' ? 'nb' : loc.language;
  const lnr = new Intl.Locale(lang + (loc.region ? '-' + loc.region : ''));
  retVal.push(lnr);
  if (loc.region) {
    const l = new Intl.Locale(lang);
    retVal.push(l);
  }
  return retVal;
}
export function localisationInitializer(localeService: LocaleService, translate: TranslateService, injector: Injector) {
  return new Promise<any>((resolve: any) => {
    // not sure what this is, docs say it "Indicates when a location is initialized."
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('en');
      // check if any locale was requested
      const requestedLocale = getResolvedPathLocale();
      if (requestedLocale) {
        // convert requested locale to angular supported format
        const angularLocale = resolveLocaleFallbackListForAngular(requestedLocale);
        // import potentially needed locale data
        importLocaleData(angularLocale)
          .then(() => {
            localeService.setLocaleParam(requestedLocale.toString().toLowerCase());

            handleLocale(localeService, translate, angularLocale).then(() => {
              resolve(null);
            });
          })
          .catch(() => {
            // failed, locale not supported. Leave in path for future support and show notification
            localeService.setLocaleParam(requestedLocale.toString().toLowerCase());
            localeService.setIsAvailable(false);
            resolve(null);
          })
        ;
      } else {
        // no locale was requested, continue with default.
        localeService.setLocaleParam(null);
        localeService.setIsAvailable(true);
        resolve(null);
      }
    });
  });
}
