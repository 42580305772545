<div
  class="card standalone fade agreements-component"
  [ngClass]="{
    'show': isReady(),
    'error': noAgreementsFound
  }"
>
  <h1 class="card-header text-center" *ngIf="!noAgreementsFound">
    {{'agreements.title'|translate}}
  </h1>
  <h1 class="card-header text-center" *ngIf="noAgreementsFound">
    <span class="icon d-block mx-auto fa fa-fw fa-2x fa-exclamation-triangle mb-2"></span>
    {{'agreements.error-title'|translate}}
  </h1>
  <form
    *ngIf="isReady()"
    [formGroup]="agreementsForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="card-body">
        <p
          class="mb-0 text-center"
          *ngIf="noAgreementsFound"
          data-test-load-failed="true"
        >
          {{'agreements.no-agreements-found'|translate}}
        </p>
      <app-duke-alert
        type="danger"
        *ngIf="submitFailed"
        data-test-accept-failed="true"
      >
        <p class="mb-0">
          {{'agreements.agreement-accept-failed'|translate}}
        </p>
      </app-duke-alert>
      <ng-container *ngIf="agreements && agreements.length && !submitFailed">
        <app-duke-alert
          type="info"
          alertClass="static"
        >
          <p *ngIf="!hasTimeToRespond" class="mb-0">
            {{'agreements.updated' | translate}}
          </p>
          <p *ngIf="hasTimeToRespond" class="mb-0"
             [innerHTML]="'agreements.updating' | translate:{ date: getResponseTimeAsString() } "
          >
          </p>
        </app-duke-alert>
        <h5>
          {{'agreements.agreements-copy' | translate}}
        </h5>
        <div [class]="resolveFormInputWrapperClass() + ' mb-0'"
             [ngClass]="{
                      'is-invalid': isFieldInvalid('agreements'),
                      'is-valid': isFieldValid('agreements')
                    }">
        <ng-container *ngFor="let agreement of agreements; let ind = index;">
            <div [class]="resolveFormInputControlWrapperClass() ">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  [id]="'agreements_' + ind"
                  [name]="'agreements_' + ind"
                  [formControlName]="'agreements_' + ind"
                  class="custom-control-input"
                  (click)="agreementsForm.get('agreements_' + ind).markAsTouched()"
                  (keypress)="agreementsForm.get('agreements_' + ind).markAsTouched()"
                  [ngClass]="{
                      'is-invalid': isFieldInvalid('agreements_' + ind),
                      'is-valid': isFieldValid('agreements_' + ind)
                    }"
                />
                <label
                  class="custom-control-label"
                  [for]="'agreements_' + ind"
                >
                  <span class="agreement-label" [innerHTML] = "'agreements.agreement-label' | translate: { name: getAgreementNameLink(agreement) }" ></span>
                  <span *ngIf="!agreement.required" class="ml-2 badge badge-muted">{{'agreements.optional-label' | translate}}</span>
                </label>
              </div>
            </div>
        </ng-container>
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('agreements') && hasFormChanges()"
          >{{'agreements.error-message.required' | translate}}</div>
        </div>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <ng-container *ngIf="noAgreementsFound || submitFailed">
          <button
            type="button"
            class="btn btn-secondary"
            data-test-continue-button="true"
            [ngClass]="{
              'btn-block': noAgreementsFound,
              'btn-lg': noAgreementsFound
            }"
            (click)="close()"
          >
            {{'agreements.continue-button.label'|translate}}
          </button>
        </ng-container>
        <ng-container *ngIf="!noAgreementsFound && !submitFailed && !hasFormChanges() && hasTimeToRespond">
          <button
            type="button"
            class="btn btn-secondary"
            data-test-submit-button="true"
            (click)="later()"
          >
            {{'agreements.later-button.label'|translate}}
          </button>
        </ng-container>
        <ng-container *ngIf="!noAgreementsFound && !submitFailed && (!hasTimeToRespond || hasFormChanges())">
          <button
            class="btn btn-success"
            type="submit"
            [disabled]="!agreementsForm.valid"
            data-test-submit-button="true"
          >
            {{'agreements.apply-button.label'|translate}}
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>
