import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ParamsService} from '../params.service';
import {AppConstants} from '../AppConstants';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AppStateService} from '../app-state.service';
import {
  Credentials,
  UserCodeForDeviceAuthorization,
  UserCodeForDeviceAuthorizationDecisionRequired
} from '../../gen';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, throwError, timer} from 'rxjs';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {HttpErrorResponse, HttpResponseBase} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

/**
 * Angular has not exposed this interface, so added here as fallback to allow proper typing.
 * If this starts to produce errors, it's likely due to angular update with included support.
 */
interface SubmitEvent extends Event {
  submitter: HTMLElement|HTMLInputElement|HTMLButtonElement;
}
@Component({
  selector: 'app-authenticate-device',
  templateUrl: './authenticate-device.component.html',
  styleUrls: ['./authenticate-device.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthenticateDeviceComponent extends StandaloneComponent implements OnInit {
  authenticateDeviceForm = new FormGroup({
    userCode: new FormControl('', [Validators.required, Validators.pattern(/^[BCDFGHJKLMNPQRSTVWXZ]{4}-[BCDFGHJKLMNPQRSTVWXZ]{4}$/)]),
    accept: new FormControl('', []),
  });
  userCode = '';
  device: any;
  queryDeviceFailed = false;
  authenticateDeviceFailed = false;
  processCompleted: string|undefined;
  completionDismissed = false;
  private tooManyAttemptsError: boolean;
  private tooManyAttemptsTimeoutCounter: any;
  private tooManyAttemptsTimeout: number;

  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    configuration: ConfigurationService,
    private paramsService: ParamsService,
    private translate: TranslateService,
    private alertHandler: AlertHandler,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(route, router, hostElement, configuration);
    this.subscribeParamsState();
  }

  ngOnInit() {
    super.init();
  }
  public hasTooManyAttemptsTimeout(): boolean {
    return this.tooManyAttemptsTimeout > 0;
  }
  signout() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.activatedRoute,
    });
  }
  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputInvalid(field: AbstractControl): boolean {
    return field.invalid && field.touched;
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }
  hasError(field?: AbstractControl): boolean {
    return false;
  }

  // noinspection JSMethodCanBeStatic
  isInputValid(field: AbstractControl): boolean {
    return field.valid && field.touched;
  }
  cancel() {
    this.completeProcess('cancel');
  }

  private subscribeParamsState() {
    this.paramsService.getParam(AppConstants.QP_USER_CODE).then((value) => {
      this.userCode = value;
      if (this.userCode) {
        this.authenticateDeviceForm.get('userCode').setValue(this.userCode);
        this.authenticateDeviceForm.get('userCode').markAsTouched();
        this.authenticateDeviceForm.updateValueAndValidity();
        if (this.authenticateDeviceForm.valid && !this.device) {
          // valid form, no device => execute query
          this.authenticateDevice();
        }
      }
    });
  }

  authenticateDevice(event?: SubmitEvent) {

    const source: HTMLButtonElement = event ? event.submitter as HTMLButtonElement : undefined;
    let accept;
    if (source && source.name === 'accept') {
      accept = source.value === 'true' ;
      this.authenticateDeviceFailed = false;
    } else {
      accept = undefined;
      this.queryDeviceFailed = false;
    }
    const cred: UserCodeForDeviceAuthorization = {
      authenticationMethod: AppConstants.AC_AM_USER_CODE,
      userCode: this.authenticateDeviceForm.get('userCode').value,
      accept: accept,
    };
    const addAuthenticationsRequest: Observable<HttpResponseBase> =
      this.appStateService.addAuthentications([cred] as Credentials, false, false)
        .pipe(catchError(error => error && (error.status === 489 || error.status === 401) ? of(error) : throwError(error)))
    ;
    addAuthenticationsRequest.subscribe(response => {

      this.tooManyAttemptsError = undefined;
      this.tooManyAttemptsTimeout = undefined;
      if (this.tooManyAttemptsTimeoutCounter) {
        this.tooManyAttemptsTimeoutCounter.unsubscribe();
      }
      this.tooManyAttemptsTimeoutCounter = undefined;
      const isErrorResponse = response instanceof HttpErrorResponse;
      if (accept === undefined) {
        if (isErrorResponse) {
          const erResp: HttpErrorResponse = response as HttpErrorResponse;
          if (erResp.error.error === 'authentication_attempts_restricted') {
            this.device = null;
            this.queryDeviceFailed = true;
            this.tooManyAttemptsError = true;
          } else if (erResp.error.error === 'authz_decision_required') {
            const er: UserCodeForDeviceAuthorizationDecisionRequired = erResp.error as UserCodeForDeviceAuthorizationDecisionRequired;
            this.device = {...er.client, requestedScope: er.requestedScope};

          } else if (erResp.error.error === 'invalid_code') {
            this.device = null;
            this.queryDeviceFailed = true;
          }

          /** @namespace erResp.error.waitSeconds **/
          if (erResp.error.waitSeconds > 2) {
            // better to not react if less than 2 seconds, as there is not enough time for the user to read the info
            this.tooManyAttemptsTimeout = erResp.error.waitSeconds;
            this.tooManyAttemptsTimeoutCounter =  timer(1000, 1000).subscribe(() => {
              this.tooManyAttemptsTimeout--;
              if (this.tooManyAttemptsTimeout <= 0) {
                this.tooManyAttemptsTimeout = undefined;
                this.tooManyAttemptsTimeoutCounter.unsubscribe();
                this.tooManyAttemptsTimeoutCounter = undefined;
              }
            });
          }
        }

      } else {
        if (!isErrorResponse) {
          this.completeProcess(accept ? 'accept' : 'reject');
        } else {
          const erResp: HttpErrorResponse = response as HttpErrorResponse;
          /** @namespace erResp.error.waitSeconds **/
          if (erResp.error.waitSeconds > 2) {
            // better to not react if less than 2 seconds, as there is not enough time for the user to read the info
            this.tooManyAttemptsTimeout = erResp.error.waitSeconds;
            this.tooManyAttemptsTimeoutCounter =  timer(1000, 1000).subscribe(() => {
              this.tooManyAttemptsTimeout--;
              if (this.tooManyAttemptsTimeout <= 0) {
                this.tooManyAttemptsTimeout = undefined;
                this.tooManyAttemptsTimeoutCounter.unsubscribe();
                this.tooManyAttemptsTimeoutCounter = undefined;
              }
            });
          }
          this.tooManyAttemptsError = 'authentication_attempts_restricted' === erResp.error.error;
          this.authenticateDeviceFailed = true;
          this.device = null;
        }
      }
    }, err => {
      this.device = null;
      if (accept === undefined) {
        this.queryDeviceFailed = true;
      } else {
        this.authenticateDeviceFailed = true;
      }
    });
  }
  closeCompleted() {
    this.completionDismissed = true;
  }
  completeProcess(key: 'cancel' | 'reject' | 'accept') {
    this.processCompleted = key;
  }
  showAuthenticateDeviceForm() {
    return !!this.device;
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('authenticate-device.window-title');
  }
}
