import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';
import {alertTransition} from '../animations';
import {ConfigurationService} from '../ui-configuration/configuration-service';

@Component({
  selector: 'app-duke-alert',
  templateUrl: './duke-alert.component.html',
  styleUrls: ['./duke-alert.component.scss'],
  animations: [
    alertTransition,
  ],
})
export class DukeAlertComponent implements OnInit, OnChanges, AfterViewInit {

  private configuration: ConfigurationService;

  resolvedNGClass: any;
  /**
   * Set show initially to false, and then to true once the dom has been rendered to allow for css animations.
   */
  show = false;

  @Input() alertClass: string;
  @Input() ngClass: any;
  @Input() icon: string;
  @Input() type: string;
  @Input() dismissible: boolean;
  @Output() close = new EventEmitter();
  @Output() open = new EventEmitter();
  // TODO: THis may not be needed or used at all, investigate and refactor if so
  @HostBinding('@alertTransition') trigger = '';
  @HostBinding('@.disabled') disableAnimations(): boolean {
    const enabled = this.configuration.getProperties().enableAnimations;
    // for disabling our java client lib anims append: |javafx\/
    const not_supported: boolean = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    return !enabled || not_supported;
  }
  constructor(private el: ElementRef, configuration: ConfigurationService) {
    this.configuration = configuration;
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.show = true;
    if (this.resolvedNGClass && this.resolvedNGClass.show === false) {
      setTimeout(() => {
        this.resolvedNGClass.show = this.show;
        this.open.emit(this);
      }, 1);
    } else if (!this.resolvedNGClass) {
      setTimeout(() => {
        this.resolvedNGClass = {
          show: this.show
        };
        this.open.emit(this);
      }, 1);
    }
  }

  ngOnChanges() {
    if (this.dismissible === undefined) {
      this.dismissible = false;
    }
    if (this.icon === undefined) {
      if (this.type === 'info') {
        this.icon = 'fa-info-circle';
      } else if (this.type === 'danger') {
        this.icon = 'fa-exclamation-triangle';
      } else if (this.type === 'success') {
        this.icon = 'fa-check-circle';
      }
    }

    const obj = {
      show: this.show,
    };
    if (this.alertClass) {
      const classes = this.alertClass.split(' ');
      for (let i = 0; i < classes.length; i += 1) {
        obj[classes[i]] = true;
      }
    }

    if (this.ngClass) {
      this.resolvedNGClass = Object.assign({}, obj, this.ngClass);
    } else {
      this.resolvedNGClass = Object.assign({}, obj);
    }
  }

  onAlertClose() {
    this.show = false;
    const styles = window.getComputedStyle(this.el.nativeElement.firstElementChild);
    let dur = 0;
    if (styles) {
      if (styles.transitionDuration && styles.transitionDuration.indexOf('ms') > 0) {
        dur += Number(styles.transitionDuration.replace('ms', ''));
      } else if (styles.transitionDuration && styles.transitionDuration.indexOf('s') > 0) {
        dur += (Number(styles.transitionDuration.replace('s', '')) * 1000);
      }

      if (styles.transitionDelay && styles.transitionDelay.indexOf('ms') > 0) {
        dur += Number(styles.transitionDelay.replace('ms', ''));
      } else if (styles.transitionDelay && styles.transitionDelay.indexOf('s') > 0) {
        dur += (Number(styles.transitionDelay.replace('s', '')) * 1000);
      }
    }
    if (this.resolvedNGClass) {
      this.resolvedNGClass.show = this.show;
    } else {
      this.resolvedNGClass = { show: this.show };
    }
    setTimeout(() => {
      // this.show = true;
      this.close.emit(this);
    }, dur);
  }

  resolveAnimations(): boolean {
    if (this.type === 'danger' || this.type === 'success') {
      if (!this.resolvedNGClass || !this.resolvedNGClass.static) {
        // floating, so no anim
        return false;
      } else {
        // static, anim ok
        return true;
      }
    } else if (!this.resolvedNGClass || !this.resolvedNGClass.floating) {
        // static, anim ok
        return true;
    } else {
        // floating, so no anim
        return false;
    }
  }
}
