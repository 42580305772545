/**
 * Localstorage based cross window event handling
 */

/**
 * Constant for the shared localstorage key
 */
const FIRE_EVENT = '10Duke.fireEvent';

/**
 * Constant for the Application identifier
 */
export const EVENT_SENDER = 'sso-ui';

/**
 * Supported event types
 */
export enum XWindowEventType {
  LOGOUT = 'LOGOUT',
  ERROR = 'ERROR',
  GET_SESSION_STORAGE = 'GET_SESSION_STORAGE',
  SET_SESSION_STORAGE = 'SET_SESSION_STORAGE',
}

/**
 * Event object allowing for possible data transfer
 */
export interface XWindowEvent {
  type: XWindowEventType;
  /**
   * Application identifier
   */
  sender?: string;
}

/**
 * Event object for logout event
 */
export interface XWindowLogoutEvent extends XWindowEvent {
  type: XWindowEventType.LOGOUT;
}

/**
 * Error object for failed event data parsing
 */
export interface XWindowErrorEvent extends XWindowEvent {
  type: XWindowEventType.ERROR;
  /**
   * Unparsed string that caused the error
   */
  data?: string;
}


/**
 * Event object for logout event
 */
export interface XWindowSetSessionStorageEvent extends XWindowEvent {
  type: XWindowEventType.SET_SESSION_STORAGE;
  data: { [key: string]: string };
}

/**
 * Event object for logout event
 */
export interface XWindowGetSessionStorageEvent extends XWindowEvent {
  type: XWindowEventType.GET_SESSION_STORAGE;
}


/**
 * Internal logic for managing listeners
 */
let listenerKeyCount = 0;

/**
 * Local state for listeners
 *
 */
const listeners: {
  [XWindowEventType.LOGOUT]: {
    [key: string]: (event: XWindowLogoutEvent) => void;
  };
  [XWindowEventType.ERROR]: {
    [key: string]: (event: XWindowErrorEvent) => void;
  };
  [XWindowEventType.SET_SESSION_STORAGE]: {
    [key: string]: (event: XWindowSetSessionStorageEvent) => void;
  };
  [XWindowEventType.GET_SESSION_STORAGE]: {
    [key: string]: (event: XWindowGetSessionStorageEvent) => void;
  };
} = {
  [XWindowEventType.LOGOUT]: {},
  [XWindowEventType.ERROR]: {},
  [XWindowEventType.SET_SESSION_STORAGE]: {},
  [XWindowEventType.GET_SESSION_STORAGE]: {},
};
/**
 * Event handler for the window storage event, should only be fired when the event originates from another window.
 * Fires XWindowEvents
 */
function onStorageEvent(event: StorageEvent): void {
  if (!event.newValue || event.newValue === '') {
    // Remove events and events that have no value and can be ignored.
    return;
  }
  if (event.key === FIRE_EVENT) {
    let evObj: XWindowEvent;
    try {
      evObj = JSON.parse(event.newValue) as XWindowEvent;
    } catch (e) {
      evObj = {
        type: XWindowEventType.ERROR,
        data: event.newValue,
      } as XWindowErrorEvent;
    }
    if (
      evObj.type === XWindowEventType.LOGOUT &&
      listeners[XWindowEventType.LOGOUT]
    ) {
      Object.keys(listeners[XWindowEventType.LOGOUT])?.forEach((key) => {
        listeners[XWindowEventType.LOGOUT][key](evObj as XWindowLogoutEvent);
      });
    } else if (
      evObj.type === XWindowEventType.SET_SESSION_STORAGE &&
      listeners[XWindowEventType.SET_SESSION_STORAGE]
    ) {
      Object.keys(listeners[XWindowEventType.SET_SESSION_STORAGE])?.forEach((key) => {
        listeners[XWindowEventType.SET_SESSION_STORAGE][key](evObj as XWindowSetSessionStorageEvent);
      });
    }  else if (
      evObj.type === XWindowEventType.GET_SESSION_STORAGE &&
      listeners[XWindowEventType.GET_SESSION_STORAGE]
    ) {
      Object.keys(listeners[XWindowEventType.GET_SESSION_STORAGE])?.forEach((key) => {
        listeners[XWindowEventType.GET_SESSION_STORAGE][key](evObj as XWindowGetSessionStorageEvent);
      });
    } else if (
      evObj.type === XWindowEventType.ERROR &&
      listeners[XWindowEventType.ERROR]
    ) {
      Object.keys(listeners[XWindowEventType.ERROR])?.forEach((key) => {
        listeners[XWindowEventType.ERROR][key](evObj as XWindowErrorEvent);
      });
    }
  }
}

/**
 * Internal flag for allowing auto init only once.
 */
const _initialized = false;
/**
 * Initializes the x-window event handling.
 */
function init(): void {
  if (!_initialized) {
    // listen for changes to localStorage
    window.addEventListener('storage', onStorageEvent, false);
    // Documentation and browser quirks are a bit unclear, but it seems that the set/remove pattern
    // is required in some cases for the events to fire.
    triggerStorageEvents('');
  }
}

/**
 * Creates a subscription object by event type, subscription listener needs to be attached separately. To attach a
 * listener, just add the function to local listeners with: `listeners[XWindowEventType][key] = listener`
 * @return Subscription object containing unsubscribe function and subscription key
 */
function createSubscription(type: XWindowEventType): {
  unsubscribe: () => void;
  key: string;
} {
  const key = 'key' + listenerKeyCount;
  const unsubscribe = () => {
    delete listeners[type][key];
  };
  listenerKeyCount += 1;

  return {
    unsubscribe,
    key,
  };
}

/**
 * Writes a string to localstorage as FIRE_EVENT and removes it immediately, causing SetItem and RemoveItem
 * WindowStorageEvents to be fired.
 * @param value, string to write and remove
 */
function triggerStorageEvents(value: string): void {
  window.localStorage.setItem(FIRE_EVENT, value);
  window.localStorage.removeItem(FIRE_EVENT);
}

const XWindowEvents = {
  /**
   * Subscribes to XWindowLogoutEvent
   * @returns Unsubscribe function.
   */
  subscribeLogout(listener: (event: XWindowLogoutEvent) => void): () => void {
    const subs = createSubscription(XWindowEventType.LOGOUT);
    listeners[XWindowEventType.LOGOUT][subs.key] = listener;
    return subs.unsubscribe;
  },

  /**
   * Fires an XWindowLogoutEvent
   */
  fireLogout(event?: XWindowLogoutEvent): void {
    triggerStorageEvents(
      JSON.stringify(
        !!event
          ? { ...event, sender: EVENT_SENDER }
          : { type: XWindowEventType.LOGOUT, sender: EVENT_SENDER }
      )
    );
  },

  /**
   * Subscribes to XWindowGetSessionStorageEvent
   * @returns Unsubscribe function.
   */
  subscribeGetSessionStorage(listener: (event: XWindowGetSessionStorageEvent) => void): () => void {
    const subs = createSubscription(XWindowEventType.GET_SESSION_STORAGE);
    listeners[XWindowEventType.GET_SESSION_STORAGE][subs.key] = listener;
    return subs.unsubscribe;
  },

  /**
   * Fires an XWindowGetSessionStorageEvent
   */
  fireGetSessionStorage(event?: XWindowGetSessionStorageEvent): void {
    triggerStorageEvents(
      JSON.stringify(
        !!event
          ? { ...event, sender: EVENT_SENDER }
          : { type: XWindowEventType.GET_SESSION_STORAGE, sender: EVENT_SENDER }
      )
    );
  },

  /**
   * Subscribes to XWindowSetSessionStorageEvent
   * @returns Unsubscribe function.
   */
  subscribeSetSessionStorage(listener: (event: XWindowSetSessionStorageEvent) => void): () => void {
    const subs = createSubscription(XWindowEventType.SET_SESSION_STORAGE);
    listeners[XWindowEventType.SET_SESSION_STORAGE][subs.key] = listener;
    return subs.unsubscribe;
  },

  /**
   * Fires an XWindowGetSessionStorageEvent
   */
  fireSetSessionStorage(event?: XWindowSetSessionStorageEvent): void {
    triggerStorageEvents(
      JSON.stringify(
        !!event
          ? { ...event, sender: EVENT_SENDER }
          : { type: XWindowEventType.SET_SESSION_STORAGE, sender: EVENT_SENDER }
      )
    );
  },
  /**
   * Subscribes to XWindowErrorEvent
   * @returns Unsubscribe function.
   */
  subscribeError(listener: (event: XWindowErrorEvent) => void): () => void {
    const subs = createSubscription(XWindowEventType.ERROR);
    listeners[XWindowEventType.ERROR][subs.key] = listener;
    return subs.unsubscribe;
  },
};

// Trigger auto init
init();

export default XWindowEvents;
