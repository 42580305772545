import { ActivatedRoute, Router } from '@angular/router';
import { StandaloneComponent } from './standalone.component';
import { AppConstants } from './AppConstants';
import { Credentials, UserNameAndPasswordCredential } from 'src/gen';
import { ElementRef, Directive } from '@angular/core';
import {ConfigurationService} from './ui-configuration/configuration-service';

/**
 * Base class for components that log in user.
 */
@Directive()
export class LoginBaseComponent extends StandaloneComponent {

  constructor(
      route: ActivatedRoute,
      router: Router,
      hostElement: ElementRef,
      configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
  }

  protected sanitizeCredentials(credentials: Credentials): Credentials {
    return credentials.map(cred => {
      let sanitizedCred = cred;
      if (AppConstants.AC_AM_PASSWORD === cred.authenticationMethod) {
        const copy = <UserNameAndPasswordCredential>Object.assign({}, cred);
        copy.password = '********';
        sanitizedCred = copy;
      }
      return sanitizedCred;
    });
  }
}
