<div
  class="card standalone forgot-password-component"
  [ngClass]="{
    'show': isReady()
  }"
>

  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'forgot-password.title'|translate}}
    </h1>
  </div>
  <form
    [formGroup]="forgotPasswordForm"
    (ngSubmit)="submit()"
  >
    <div class="card-body">
      <app-duke-alert
        type="info"
        alertClass="mb-0"
      >
        <h2 class="h6 font-weight-bold">
          {{'forgot-password.info-box.title'|translate}}
        </h2>
        <p class="mb-0">
          {{'forgot-password.info-box.copy'|translate}}
        </p>
      </app-duke-alert>
      <hr />
      <div
        app-form-input
        #email
        field="email"
        size="lg"
        autocomplete="username email"
        label="{{'forgot-password.email.label'|translate}}"
        [isInvalid]="isFieldInvalid(forgotPasswordForm.get('email'))"
        [isValid]="isFieldValid(forgotPasswordForm.get('email'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="isFieldInvalid(forgotPasswordForm.get('email'))"
        >{{'forgot-password.email.error-message.required-or-invalid'|translate}}</div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <span
          class="text-danger order-3 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
          [@.disabled]="disableAnimations()"
        >
          <span class="icon fa fa-fw fa-exclamation-triangle"></span>
          <span>
            {{'forgot-password.submit-error.notification'|translate}}
          </span>
        </span>
        <button
          class="btn btn-primary"
          data-test-submit-button
          type="submit"
          [disabled]="!forgotPasswordForm.valid"
        >
          {{'forgot-password.next-button.label'|translate}}
        </button>
        <a
          data-test-login-link
          [routerLink]="getAppConstant('PATH_LOGIN')"
          [queryParams]="wrapAsObject([
            getAppConstant('QP_EMAIL'), forgotPasswordForm.get('email').value,
            getAppConstant('QP_LOGIN_HINT'), undefined,
          ])"
          queryParamsHandling="merge"
          class="btn btn-link order-3 mr-auto"
        >
          {{'forgot-password.cancel-button.label'|translate}}
        </a>
      </div>
    </div>
  </form>
</div>
