import { Injectable } from '@angular/core';

@Injectable()
export abstract class Logger {

    public abstract debug(...obj: any): void;
    public abstract debugObjs(msg: string, ...subst: any): void;
    public abstract warn(...obj: any): void;
    public abstract warnObjs(msg: string, ...subst: any): void;
    public abstract error(...obj: any): void;
    public abstract errorObjs(msg: string, ...subst: any): void;
    public abstract info(...obj: any): void;
    public abstract infoObjs(msg: string, ...subst: any): void;
}
