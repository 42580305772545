<ngb-alert
  [type]="type"
  class="d-flex align-items-stretch flex-column flex-sm-row"
  [ngClass]="resolvedNGClass"
  [dismissible] = "dismissible"
  (closed)="onAlertClose()"
  [animation]="resolveAnimations()"
>
  <span
    class="icon fa fa-fw flex-shrink-0 fa-2x align-middle d-flex {{icon}}"
    *ngIf="icon"
  ></span>
  <div class="align-middle">
    <ng-content></ng-content>
  </div>
</ngb-alert>
