import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
export interface Country {
  code: string;
  name: string;
}

/**
 * A special marker function for extracting translation keys from source code.
 * Used by ngx-translate-extract via npm scripts defined in package.json.
 * As translation keys are 'namespaced' we must include the namespace in the call
 * for the string parser. To avoid having to define the values twice, that
 * namespacing is stripped from the return value, leaving us with an array of
 * pure values.
 *
 * @param s Array of translation keys.
 * @returns  Array of translation keys with namespacing stripped.
 */
function stripPrefix(s: string[]): string[] {
  return s.map(i => i.split('.').pop());
}

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  /**
   * List of country codes. The 'country-name' prefix is stripped from each
   * value by the translation marker function call,
   * leaving us with ['AF', 'AX', ...]
   */
  private static countryCodes = stripPrefix(_([
    'country-name.AF', 'country-name.AX', 'country-name.AL', 'country-name.DZ', 'country-name.AS', 'country-name.AD', 'country-name.AO',
    'country-name.AI', 'country-name.AQ', 'country-name.AG', 'country-name.AR', 'country-name.AM', 'country-name.AW', 'country-name.AU',
    'country-name.AT', 'country-name.AZ', 'country-name.BS', 'country-name.BH', 'country-name.BD', 'country-name.BB', 'country-name.BY',
    'country-name.BE', 'country-name.BZ', 'country-name.BJ', 'country-name.BM', 'country-name.BT', 'country-name.BO', 'country-name.BA',
    'country-name.BW', 'country-name.BV', 'country-name.BR', 'country-name.IO', 'country-name.BN', 'country-name.BG', 'country-name.BF',
    'country-name.BI', 'country-name.KH', 'country-name.CM', 'country-name.CA', 'country-name.CV', 'country-name.KY', 'country-name.CF',
    'country-name.TD', 'country-name.CL', 'country-name.CN', 'country-name.CX', 'country-name.CC', 'country-name.CO', 'country-name.KM',
    'country-name.CG', 'country-name.CD', 'country-name.CK', 'country-name.CR', 'country-name.CI', 'country-name.HR', 'country-name.CU',
    'country-name.CY', 'country-name.CZ', 'country-name.DK', 'country-name.DJ', 'country-name.DM', 'country-name.DO', 'country-name.EC',
    'country-name.EG', 'country-name.SV', 'country-name.GQ', 'country-name.ER', 'country-name.EE', 'country-name.ET', 'country-name.FK',
    'country-name.FO', 'country-name.FJ', 'country-name.FI', 'country-name.FR', 'country-name.GF', 'country-name.PF', 'country-name.TF',
    'country-name.GA', 'country-name.GM', 'country-name.GE', 'country-name.DE', 'country-name.GH', 'country-name.GI', 'country-name.GR',
    'country-name.GL', 'country-name.GD', 'country-name.GP', 'country-name.GU', 'country-name.GT', 'country-name.GG', 'country-name.GN',
    'country-name.GW', 'country-name.GY', 'country-name.HT', 'country-name.HM', 'country-name.VA', 'country-name.HN', 'country-name.HK',
    'country-name.HU', 'country-name.IS', 'country-name.IN', 'country-name.ID', 'country-name.IR', 'country-name.IQ', 'country-name.IE',
    'country-name.IM', 'country-name.IL', 'country-name.IT', 'country-name.JM', 'country-name.JP', 'country-name.JE', 'country-name.JO',
    'country-name.KZ', 'country-name.KE', 'country-name.KI', 'country-name.KP', 'country-name.KR', 'country-name.KW', 'country-name.KG',
    'country-name.LA', 'country-name.LV', 'country-name.LB', 'country-name.LS', 'country-name.LR', 'country-name.LY', 'country-name.LI',
    'country-name.LT', 'country-name.LU', 'country-name.MO', 'country-name.MK', 'country-name.MG', 'country-name.MW', 'country-name.MY',
    'country-name.MV', 'country-name.ML', 'country-name.MT', 'country-name.MH', 'country-name.MQ', 'country-name.MR', 'country-name.MU',
    'country-name.YT', 'country-name.MX', 'country-name.FM', 'country-name.MD', 'country-name.MC', 'country-name.MN', 'country-name.ME',
    'country-name.MS', 'country-name.MA', 'country-name.MZ', 'country-name.MM', 'country-name.NA', 'country-name.NR', 'country-name.NP',
    'country-name.NL', 'country-name.AN', 'country-name.NC', 'country-name.NZ', 'country-name.NI', 'country-name.NE', 'country-name.NG',
    'country-name.NU', 'country-name.NF', 'country-name.MP', 'country-name.NO', 'country-name.OM', 'country-name.PK', 'country-name.PW',
    'country-name.PS', 'country-name.PA', 'country-name.PG', 'country-name.PY', 'country-name.PE', 'country-name.PH', 'country-name.PN',
    'country-name.PL', 'country-name.PT', 'country-name.PR', 'country-name.QA', 'country-name.RE', 'country-name.RO', 'country-name.RU',
    'country-name.RW', 'country-name.BL', 'country-name.SH', 'country-name.KN', 'country-name.LC', 'country-name.MF', 'country-name.PM',
    'country-name.VC', 'country-name.WS', 'country-name.SM', 'country-name.ST', 'country-name.SA', 'country-name.SN', 'country-name.RS',
    'country-name.SC', 'country-name.SL', 'country-name.SG', 'country-name.SK', 'country-name.SI', 'country-name.SB', 'country-name.SO',
    'country-name.ZA', 'country-name.GS', 'country-name.ES', 'country-name.LK', 'country-name.SD', 'country-name.SR', 'country-name.SJ',
    'country-name.SZ', 'country-name.SE', 'country-name.CH', 'country-name.SY', 'country-name.TW', 'country-name.TJ', 'country-name.TZ',
    'country-name.TH', 'country-name.TL', 'country-name.TG', 'country-name.TK', 'country-name.TO', 'country-name.TT', 'country-name.TN',
    'country-name.TR', 'country-name.TM', 'country-name.TC', 'country-name.TV', 'country-name.UG', 'country-name.UA', 'country-name.AE',
    'country-name.GB', 'country-name.US', 'country-name.UM', 'country-name.UY', 'country-name.UZ', 'country-name.VU', 'country-name.VE',
    'country-name.VN', 'country-name.VG', 'country-name.VI', 'country-name.WF', 'country-name.EH', 'country-name.YE', 'country-name.ZM',
    'country-name.ZW',
  ]));
  constructor(
    private translate: TranslateService
  ) {}

  public alphabetical(): Observable<Array<Country>> {
    return new Observable<Array<Country>>(subscriber => {
      this.list().subscribe(res => {
        subscriber.next(res.sort((a: Country, b: Country) => a.name.localeCompare(b.name)));
        subscriber.complete();
      });
    });
  }

  public list(): Observable<Array<Country>> {
    return new Observable<Array<Country>>(subscriber => {
      const authenticationInfosRequest = forkJoin(CountriesService.countryCodes.map(code => this.createCountryItem(code)));
      authenticationInfosRequest.subscribe(res => {
        subscriber.next(res);
        subscriber.complete();
      });
    });
  }

  public getCountryName(code: string): Observable<string> {
    return new Observable<string>(subscriber => {
      // assign the `translate` in to variable, as `this.translate.get(` is used when extracting strings,
      // and won't work with dynamic the `code`
      const trans = this.translate;
      trans.get('country-name.' + code).subscribe(res => {
        subscriber.next(res);
        subscriber.complete();
      });
    });
  }

  private createCountryItem(code: string): Observable<Country> {
    return new Observable<Country>(subscriber => {
      this.getCountryName(code).subscribe(res => {
        subscriber.next(
          {
            code: code,
            name: res,
          });
        subscriber.complete();
      });
    });
  }

}
