// transfers sessionStorage from one tab to another
import XWindowEvents, {EVENT_SENDER, XWindowEventType} from './x-window-events';

/**
 * Strips out members that do not start with SSO_UI_STORE_PREFIX
 */
function stripUnknownSessionStoreItems(data: { [key: string]: string }): { [key: string]: string }|undefined {
  if (data) {
    const keys = Object.keys(data).filter((k) => k.startsWith(SSO_UI_STORE_PREFIX));
    const retVal = {};
    keys.forEach((k) => {
      retVal[k] = data[k];
    });
    return retVal;
  } else {
    return undefined;
  }
}

export default function initSharedSessionStorage() {
  XWindowEvents.subscribeGetSessionStorage((evt) => {
    // another tab asked for the sessionStorage -> send it
    if (evt.sender === EVENT_SENDER) {
      const data = stripUnknownSessionStoreItems(window.sessionStorage);
      XWindowEvents.fireSetSessionStorage({
        type: XWindowEventType.SET_SESSION_STORAGE,
        data,
      });
    }
  });
  XWindowEvents.subscribeSetSessionStorage((evt) => {
  if (evt.sender === EVENT_SENDER && !window.sessionStorage.length) {
      // another tab sent data <- get it
      const data = stripUnknownSessionStoreItems(evt.data);
      if (data) {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i += 1) {
          window.sessionStorage.setItem(keys[i], data[keys[i]]);
        }
      }
    }
  });
  XWindowEvents.fireGetSessionStorage();
}
const SSO_UI_STORE_PREFIX = 'sso-ui.';
function getItem(k: string) {
  return window.sessionStorage.getItem(SSO_UI_STORE_PREFIX + k);
}
function setItem(k: string, v: string) {
  window.sessionStorage.setItem(SSO_UI_STORE_PREFIX + k, v);
}

export const SharedSessionStorage = {
  getItem,
  setItem
};
