import {
  DEFAULT_UI_CONFIGURATION,
  SsoUIConfiguration,
  SsoUIField,
  SsoUILocaleSwitcherConfiguration,
  SsoUIOutputType
} from './ssoui-configuration';
import branding from '../../branding.json';
import {compareVersions} from '../../util/semantic-version';
const cloneDeep = require('lodash.clonedeep');

/**
 * Ensures that inputted fields includes all required and only supported fields
 * @param fields The fields to ensure
 * @param required List of required field
 * @param supported List of supported fields
 * @returns The resolved fields
 */
function ensureValidSetOfFields(fields: SsoUIField[], required: string[], supported: string[]): SsoUIField[] {
  const retVal: SsoUIField[] = [];
  const f = cloneDeep(fields);
  // filter out unsupported
  f.filter((val, ind) => f.findIndex(val2 => val2.key === val.key) === ind)
    // check that the fields are supported
    .forEach((val) => {
      if (supported.indexOf(val.key) >= 0) {
        if (!val.required && required.indexOf(val.key) >= 0) {
          console.error('Configured field marked as not required, but it is required by the system: ' + val.key);
          val.required = true;
        }
        retVal.push(val);
      } else {
        console.error('Configured field not supported: %o', val.key);
      }
    })
  ;
  // add minimum set
  required.forEach((val) => {
    if (retVal.findIndex((value) => value.key === val) < 0) {
      console.error('Minimum field requirement missing from configuration: %o', val);
      retVal.push({ key: val, required: true });
    }
  });

  return retVal;
}

/**
 * Removes keys for deprecated functionality that can no longer be supported
 */
function removeDeprecatedKeys(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (config && config.functionality && config.functionality['show-email-validation-code-input'] !== undefined) {
      delete config.functionality['show-email-validation-code-input'];
      console.warn('Configuration field "show-email-validation-code-input" has been deprecated and is no longer supported');
  }
  return config;
}

function migrateConfTo_0_1_0(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);

  if (compareVersions('0.1', config.version || '') > 0) {
    // transform the old "locale-switcher" to the new "localization" format
    if (
      config.functionality &&
      (config.functionality as any)['locale-switcher']
    ) {
      if (!config.functionality.localization) {
        // no type exists for the old conf format, so we cast to any
        const clone = cloneDeep(
          (config.functionality as any)['locale-switcher']
        ) as any;
        if (clone.show !== undefined) {
          clone['show-switcher'] = clone.show;
          delete clone.show;
        }
        config.functionality.localization =
          clone as SsoUILocaleSwitcherConfiguration;
      }
      delete (config.functionality as any)['locale-switcher'];
    }
    config.version = '0.1.0';
  }
  return config;

}
function migrateConfTo_0_1_1(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.1.1', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.1.1';
  }
  return config;
}
function migrateConfTo_0_2_0(conf: SsoUIConfiguration): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.2.0', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.2.0';
    if (config.functionality && config.functionality['enable-recovery-emails'] !== undefined) {
      // ensure the newly added recovery emails can not be activated with old conf as their UI does not function properly with the
      // login email functionality (readonly/hidden) that was removed.
      delete config.functionality['enable-recovery-emails'];
    }

  }
  return config;
}

function migrateConfTo_0_2_1(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.2.1', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.2.1';
  }
  return config;
}

function migrateConfTo_0_3_0(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.3.0', config.version || '') > 0) {
    // transform the old "acceptTsAndCs" field from registration to the new "agreements"
    if (
      config.functionality &&
      (config.functionality as any).registration &&
      (config.functionality as any).registration.fields
    ) {
      const ind = (config.functionality as any).registration.fields.findIndex((f) => f.key === 'acceptTsAndCs');
      if (ind >= 0) {
        (config.functionality as any).registration.fields[ind] = {
          ...(config.functionality as any).registration.fields[ind],
          key: 'agreements',
        };
      }
    }
    config.version = '0.3.0';
  }
  return config;
}

function migrateConfTo_0_3_1(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.3.1', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.3.1';
  }
  return config;
}

function migrateConfTo_0_3_2(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.3.2', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.3.2';
  }
  return config;
}
function migrateConfTo_0_3_3(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.3.3', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.3.3';
  }
  return config;
}
function migrateConfTo_0_4_0(conf: SsoUIConfiguration
): SsoUIConfiguration {
  const config = cloneDeep(conf);
  if (compareVersions('0.4.0', config.version || '') > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    config.version = '0.4.0';
  }
  return config;
}

export function migrateConfToLatest(
  conf: SsoUIConfiguration
): SsoUIConfiguration {
  let config: SsoUIConfiguration =  cloneDeep(conf) as SsoUIConfiguration;
  const inputIsEmpty = Object.keys(config).length === 0;
  if (!inputIsEmpty) {

    config = removeDeprecatedKeys(config);

    const versionComparisonResult = compareVersions(
      DEFAULT_UI_CONFIGURATION.version as string,
      config.version || ''
    );
    if (versionComparisonResult > 0) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(
          'UIConfiguration version is outdated (version: %o). Please migrate the configuration to latest (version %o).',
          config.version,
          DEFAULT_UI_CONFIGURATION.version
        );
      }
      config = migrateConfTo_0_1_0(config);
      config = migrateConfTo_0_1_1(config);
      config = migrateConfTo_0_2_0(config);
      config = migrateConfTo_0_2_1(config);
      config = migrateConfTo_0_3_0(config);
      config = migrateConfTo_0_3_1(config);
      config = migrateConfTo_0_3_2(config);
      config = migrateConfTo_0_3_3(config);
      config = migrateConfTo_0_4_0(config);
      // NOTE: Remember to update the service name script to use latest when a new version is added
    } else if (versionComparisonResult < 0) {
      throw new Error(
        'Configuration version (' +
        config.version +
        ') not supported. Latest version is (' +
        DEFAULT_UI_CONFIGURATION.version +
        ')'
      );
    }
  }
  return config;
}
export function mergeInDefaults(
  conf: SsoUIConfiguration,
  version: string,
  FIELD_CONFIG: any
): any {
  // resolve the defaults for the conf version
  const defaults = resolveDefaults(version);

// assign conf into variables for easier usage, for missing conf input, use defaults.
  const functionality = cloneDeep(conf.functionality || defaults.functionality);
  const locale = cloneDeep(functionality.localization || defaults.functionality.localization);
  const loginToContinueNotification = cloneDeep(
    functionality['login-to-continue-notification'] || defaults.functionality['login-to-continue-notification'])
  ;
  const pendingActionNotification = cloneDeep(
    functionality['pending-action-notification'] || defaults.functionality['pending-action-notification'])
  ;
  const profile = cloneDeep(functionality.profile || defaults.functionality.profile);
  const profile_personal = cloneDeep(profile.personal || defaults.functionality.profile.personal);
  const profile_contact = cloneDeep(profile.contact || defaults.functionality.profile.contact);
  // the login conf was removed in 0.2.0, but still supported for older and used in the resolved conf passed on to the component.
  // this ensures that ui conf versions <0.2.0 may still use it but including it with v >= 0.2.0 has not effect
  const profile_login = compareVersions('0.2.0', version) > 0 && (profile.login || (defaults.functionality.profile as any).login)
    ? cloneDeep(profile.login || (defaults.functionality.profile as any).login)
    : null;
  const registration = cloneDeep(functionality.registration || defaults.functionality.registration);
  const login = cloneDeep(functionality.login || defaults.functionality.login);

  const propertiesHolder: any = {
    serviceName: '' + (conf['service-name'] || branding['service-name'] || defaults['service-name']),
    showHeader: (conf.output !== undefined ? conf.output : defaults.output) !== SsoUIOutputType.CONTENT_ONLY,
    showFooter: (conf.output !== undefined ? conf.output : defaults.output) !== SsoUIOutputType.CONTENT_ONLY,
    showSessionPanel: (conf.output !== undefined ? conf.output : defaults.output) !== SsoUIOutputType.CONTENT_ONLY,
    showLoginToContinue: true === (
      loginToContinueNotification.show !== undefined ?
        loginToContinueNotification.show :
        defaults.functionality['login-to-continue-notification'].show
    ),
    logoutIdleUserTimeout:
      functionality['logout-idle-user-timeout'] !== undefined ?
        functionality['logout-idle-user-timeout'] :
        DEFAULT_UI_CONFIGURATION.functionality['logout-idle-user-timeout'],
    logoutIdleUserWarningTimeout:
      functionality['logout-idle-user-warning-timeout'] !== undefined ?
        functionality['logout-idle-user-warning-timeout'] :
        DEFAULT_UI_CONFIGURATION.functionality['logout-idle-user-warning-timeout'],
    showPendingAction: true === (
      pendingActionNotification.show !== undefined ?
        pendingActionNotification.show :
        defaults.functionality['pending-action-notification'].show
    ),
    showResetPasswordCodeInput: true === (
      functionality['show-reset-password-code-input'] !== undefined ?
        functionality['show-reset-password-code-input'] :
        defaults.functionality['show-reset-password-code-input']
    ),
    showResetPasswordFieldsWithoutCode: true === (
      functionality['show-reset-password-fields-without-code'] !== undefined ?
        functionality['show-reset-password-fields-without-code'] :
        defaults.functionality['show-reset-password-fields-without-code']
    ),
    showActivateUserCodeInput: true === (
      functionality['show-activate-user-code-input'] !== undefined ?
        functionality['show-activate-user-code-input'] :
        defaults.functionality['show-activate-user-code-input']
    ),
    supportedLocales: cloneDeep(locale.locales && locale.locales.length ?
      locale.locales :
      defaults.functionality.localization.locales
    ),
    defaultLocale: locale.default ? locale.default : defaults.functionality.localization.default,
    showLocaleSwitcher: true === (
      locale['show-switcher'] !== undefined ? locale['show-switcher'] : defaults.functionality.localization['show-switcher']
    ),
    registrationSendValidationEmail: true === (registration['send-validation-email'] !== undefined ?
      registration['send-validation-email'] :
      defaults.functionality.registration['send-validation-email']),
    loginSecondaryFlowsInFooter: true === (login['secondary-flows-in-footer'] !== undefined ?
      login['secondary-flows-in-footer'] :
      defaults.functionality.login['secondary-flows-in-footer']),
    passwordValidation: FIELD_CONFIG.PASSWORD_VALIDATION ? new RegExp(FIELD_CONFIG.PASSWORD_VALIDATION) : /.*/,
    allowedOriginsForNextParam:
      functionality['allowed-origins-for-next-param'] !== undefined ?
        functionality['allowed-origins-for-next-param'] :
        defaults.functionality['allowed-origins-for-next-param'],
    allowedDeviceRedirectPatterns:
      functionality['allowed-device-redirect-patterns'] !== undefined ?
        functionality['allowed-device-redirect-patterns'] :
        defaults.functionality['allowed-device-redirect-patterns'],
    enableRecoveryEmails: functionality['enable-recovery-emails'] !== undefined ?
      functionality['enable-recovery-emails'] :
      defaults.functionality['enable-recovery-emails'],
    enableAnimations: functionality['enable-animations'] !== undefined ?
      functionality['enable-animations'] :
      defaults.functionality['enable-animations'],
  };
  propertiesHolder.profilePersonalFields =
    cloneDeep(ensureValidSetOfFields(
      profile_personal.fields || defaults.functionality.profile.personal.fields,
      FIELD_CONFIG.PROFILE_REQUIRED_PERSONAL_FIELDS,
      FIELD_CONFIG.PROFILE_SUPPORTED_PERSONAL_FIELDS))
  ;
  propertiesHolder.profileContactFields =
    cloneDeep(ensureValidSetOfFields(
      profile_contact.fields || defaults.functionality.profile.contact.fields,
      FIELD_CONFIG.PROFILE_REQUIRED_CONTACT_FIELDS,
      FIELD_CONFIG.PROFILE_SUPPORTED_CONTACT_FIELDS))
  ;
  if (profile_login) {
    propertiesHolder.profileLoginFields =
      cloneDeep(ensureValidSetOfFields(
        profile_login.fields || (defaults.functionality.profile as any).login.fields,
        FIELD_CONFIG.PROFILE_REQUIRED_LOGIN_FIELDS,
        FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS))
    ;
  } else {
    propertiesHolder.profileLoginFields = [
      FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS.find((f) => f === 'email'),
      FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS.find((f) => f === 'password'),
      FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS.find((f) => f === 'totp'),
    ]
      .concat(FIELD_CONFIG.PROFILE_REQUIRED_LOGIN_FIELDS.filter((f) => f !== 'email' && f !== 'password' && f !== 'totp'))
      .filter((f) => !!f)
      .map((f) => {
        return {
          key: f,
          required: !!FIELD_CONFIG.PROFILE_REQUIRED_LOGIN_FIELDS.find((r) => r === f)
        };
      })
    ;
  }
  propertiesHolder.registrationFields =
    cloneDeep(ensureValidSetOfFields(
      registration.fields || defaults.functionality.registration.fields,
      FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS,
      FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS))
  ;
  return propertiesHolder;
}

/**
 * Utility for resolving default values matching the version of the used ui configuration
 */
function resolveDefaults(version: string): SsoUIConfiguration {
  const conf = cloneDeep(DEFAULT_UI_CONFIGURATION);
  if (
    compareVersions(DEFAULT_UI_CONFIGURATION.version as string, version) > 0
  ) {
    // NOTE: Remember to update the service name script to use latest when a new version is added
    // old version
    if (compareVersions('0.4.0', version) > 0) {
      // version is older than 0.3.3
      // new key was added, only affects the new user device client invitation proxy page
    }
    if (compareVersions('0.3.3', version) > 0) {
      // version is older than 0.3.3
      // new key was added, only affects the new user activation view
    }
    if (compareVersions('0.3.2', version) > 0) {
      // version is older than 0.3.2
      // restore old default layout
      conf.functionality['login'] = {
        'secondary-flows-in-footer': false,
      };
    }
    if (compareVersions('0.3.1', version) > 0) {
      // version is older than 0.3.0
      // new key was added, default functionality is the same as before so no need to do anything
    }
    if (compareVersions('0.3.0', version) > 0) {
      // version is older than 0.3.0
      // Only a field key value was changed, no need to change anything
    }
    if (compareVersions('0.2.1', version) > 0) {
      // version is older than 0.2.1
      // disable autologout
      conf.functionality['logout-idle-user-timeout'] = 0;
      conf.functionality['logout-idle-user-warning-timeout'] = 0;
    }
    if (compareVersions('0.2.0', version) > 0) {
      // version is older than 0.2.0
      conf.functionality['enable-recovery-emails'] = false;
      conf.functionality.profile.login = {
        fields: [
          { key: 'email', required: true },
          { key: 'password', required: true },
          { key: 'totp', required: false }
        ]
      };
    }
    if (compareVersions('0.1.1', version) > 0) {
      // version is older than 0.1.1
      // remove new locales
      conf.functionality.localization.locales =
        conf.functionality.localization.locales.filter(
          (l) => l.value !== 'cs' && l.value !== 'sk'
        );
    }
    if (compareVersions('0.1', version) > 0) {
      // version is older than 0.1
      // restore removed old locales
      conf.functionality.localization.locales.push({'value': 'ja', 'label': '日本語'});
      // This functionality has been deprecated and can no longer be suported. Leaving the old migration code in comments for
      // documentation purposes
      // conf.functionality['show-email-validation-code-input'] = true;
    }
  }
  return conf;
}
