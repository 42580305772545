<div
  class="card standalone activate-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'activate.title'|translate}}
    </h1>
  </div>
  <div>
    <div
      id="passwordTab"
    >
      <form
        [formGroup]="activateUserForm"
        (ngSubmit)="submit()"
      >
        <div class="card-body">
          <ng-container *ngIf="hasSession()">
            <app-duke-alert
              type="danger"
              alertClass="static"
            >
              <h4>
                {{'activate.activation-error.logged-in-title' | translate}}
              </h4>
              <p className="mb-0">
                {{'activate.activation-error.logged-in-copy' | translate}}
              </p>
            </app-duke-alert>
          </ng-container>
          <ng-container *ngIf="!hasSession()">
            <app-duke-alert
              type="info"
              alertClass="mb-0"
              *ngIf="showCodeInput || (isInputValid(activateUserForm.get('email')) && isInputValid(activateUserForm.get('code')))"
            >
              <p>
                {{'activate.activation-info'|translate}}
              </p>
            </app-duke-alert>
            <app-duke-alert
              type="danger"
              alertClass="static mb-0"
              *ngIf="!showCodeInput && (!isInputValid(activateUserForm.get('email')) || !isInputValid(activateUserForm.get('code')))"
            >
              <h4 className="alert-title">
                {{'activate.activation-missing-parameters-info.title'|translate}}
              </h4>
              <p>
                {{'activate.activation-missing-parameters-info.message'|translate}}
              </p>
            </app-duke-alert>
            <ng-container
              *ngIf="showCodeInput || (isInputValid(activateUserForm.get('email')) && isInputValid(activateUserForm.get('code')))"
            >
            <hr />
            <div
              app-form-input
              field="email"
              label="{{'activate.email.label' | translate}}"
              [isInvalid]="isFieldInvalid(activateUserForm.get('email'))"
              [isValid]="isFieldValid(activateUserForm.get('email'))"
            >
              <div
                app-form-input-feedback
                [type]="'info'"
                *ngIf="!isFieldInvalid(activateUserForm.get('email'))"
              >
                {{'activate.email.info-message' | translate}}
              </div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="isFieldInvalid(activateUserForm.get('email')) && !activateUserForm.get('email').value"
              >{{'activate.email.error-message.required' | translate}}</div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="isFieldInvalid(activateUserForm.get('email')) && !!activateUserForm.get('email').value"
              >{{'activate.email.error-message.invalid' | translate}}</div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="!isFieldInvalid(activateUserForm.get('email')) && hasError(activateUserForm.get('email')) && activateUserForm.get('email').value"
              >{{'activate.email.error-message.invalid' | translate}}</div>
            </div>
            <input *ngIf="!showCodeInput"
                   type="hidden"
                   name="code"
                   id="code"
                   formControlName="code"
            />
            <div
              *ngIf="showCodeInput"
              app-form-input
              field="code"
              label="{{'activate.code.label' | translate}}"
              [isInvalid]="isFieldInvalid(activateUserForm.get('code'))"
              [isValid]="isFieldValid(activateUserForm.get('code'))"
            >
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="isFieldInvalid(activateUserForm.get('code')) && !activateUserForm.get('code').value"
              >{{'activate.code.error-message.required' | translate}}</div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="hasError(activateUserForm.get('code')) && activateUserForm.get('code').value"
              >{{'activate.code.error-message.invalid' | translate}}</div>
            </div>
            <div
              app-form-input
              field="password"
              type="password"
              label="{{'activate.password.label' | translate}}"
              autocomplete="new-password"
              [isInvalid]="isFieldInvalid(activateUserForm.get('password'))"
              [isValid]="isFieldValid(activateUserForm.get('password'))"
            >
              <div
                app-form-input-feedback
                [type]="!isFieldInvalid(activateUserForm.get('password')) ? 'info' : 'invalid'"

              ><ng-container *ngIf="!isFieldInvalid(activateUserForm.get('password'))">{{'activate.password.info' | translate}}</ng-container>
                <ng-container *ngIf="isFieldInvalid(activateUserForm.get('password'))">{{'activate.password.error-message.required-or-invalid' | translate}}</ng-container>
              </div>
            </div>
            <div
              app-form-input
              field="confirmPassword"
              type="password"
              label="{{'activate.confirm-password.label' | translate}}"
              autocomplete="new-password"
              [isInvalid]="isFieldInvalid(activateUserForm.get('confirmPassword'))"
              [isValid]="isFieldValid(activateUserForm.get('confirmPassword'))"
            >
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="!hasError(activateUserForm.get('confirmPassword'))"
              >{{'activate.confirm-password.error-message.required-or-invalid' | translate}}</div>
            </div>
            <app-duke-alert
              data-test-submit-failed-message
              type="danger"
              alertClass="mb-0"
              *ngIf="hasError()"
              dismissible="true"
              (open)="alertHandler.onOpen($event);"
              (close)="alertHandler.onClose($event); genericError = undefined;"
            >
              <p class="mb-0" *ngIf="!tooManyAttemptsError && !invalidCodeError">
                {{'activate.submit-error.message.failed' | translate}}
              </p>
              <p class="mb-0" *ngIf="!tooManyAttemptsError && invalidCodeError">
                {{'activate.submit-error.message.reset_password_not_authorized' | translate}}
              </p>
              <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'activate.submit-error.message.tooManyAttempts' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError  && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'activate.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'activate.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
            </app-duke-alert>
            </ng-container>
          </ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showActivationFailed()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'activate.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'activate.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
                </span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showActivationFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span  [innerHTML] = "'activate.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
            <button
              *ngIf="!hasSession()"
              data-test-next-button
              type="submit"
              class="btn btn-success"
              [disabled]="!activateUserForm.valid || hasTooManyAttemptsTimeout()"
            >
              {{'activate.login-button.label' | translate}}
            </button>
            <button
              *ngIf="hasSession()"
              data-test-next-button
              type="button"
              class="btn btn-primary"
              (click)="continueFromInvalidActivation()"
            >
              {{'activate.continue-button.label' | translate}}
            </button>
            <button
              data-test-cancel-reset-password
              type="button"
              class="btn btn-secondary order-3 mr-auto"
              (click)="cancelActivation()"
            >
              {{'activate.cancel-button.label' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
