import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { Logger } from '../util/logger';
import { HttpParams } from '@angular/common/http';
import { AppConstants } from './AppConstants';

export interface Params {
  [name: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class ParamsService {
  public params: BehaviorSubject<Params> = new BehaviorSubject<Params>(undefined);

  public static getParamSync(name: string): string {
    let url = window.location.href;
    let value = null;
    if (url.includes('?')) {
      if (url.includes('#')) {
        url = url.split('#')[0];
      }
      const params = new HttpParams({ fromString: url.split('?')[1] });
      value = params.get(name);
      value = value && value !== '' && value !== 'null' ? value : null;
    }
    return value;
  }

  public static getParamsSync(): { [name: string]: string } {
    let url = window.location.href;
    if (!url.includes('?')) {
      return {};
    } else {
      if (url.includes('#')) {
        url = url.split('#')[0];
      }
      const params = new HttpParams({fromString: url.split('?')[1]});
      return params.keys().reduce<{ [name: string]: string }>((map, name) => {
        map[name] = params.get(name) as string;
        return map;
      }, {});
    }
  }

  constructor(private logger: Logger) { }

  /**
   * Gets value of the 'next' parameter by calling getParam.
   * @see getParam
   */
  public getNextUrl(): Promise<string> {
    return this.getParam(AppConstants.QP_NEXT);
  }
  /**
   * Gets current value of a parameter. This method both updates the params Subject and returns a Promise for the parameter value.
   * @param name Name of the parameter to get.
   * @returns Promise for the parameter value. Null value will be returned for missing value, empty value and 'null' string value.
   */
  public getParam(name: string): Promise<string> {
    const value: string = ParamsService.getParamSync(name);
    this.updateParam(name, value);
    return new Promise<string>((resolve) => {
      resolve(value);
    });
  }

  /**
   * Updates the params Subject with the given value.
   * @param name Name of the parameter to update.
   * @param value The new parameter value.
   */
  private updateParam(name: string, value: string) {
    const oldParams = this.params.value;
    const newParams = oldParams ? Object.assign({}, oldParams) : {};
    newParams[name] = value;
    this.params.next(newParams);
  }
}
