import { Injectable } from '@angular/core';
import {Observable, fromEvent, merge, timer, Subscription, BehaviorSubject} from 'rxjs';
import {ConfigurationService} from './ui-configuration/configuration-service';
@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idle: Observable<any> = new Observable();
  private timer: Subscription;
  private timeOutMilliSeconds = 1000;
  private warningMilliSeconds = 500;
  private idleSubscription: Subscription = new Subscription();
  public expired: BehaviorSubject<number|boolean> = new BehaviorSubject<number|boolean>(false);
  constructor(private configuration: ConfigurationService) {}
  public startWatching(): Observable<any> {
    const timeOutSeconds: number = this.configuration.getProperties().logoutIdleUserTimeout;
    const warningSeconds: number = this.configuration.getProperties().logoutIdleUserWarningTimeout;
    this.idle = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.warningMilliSeconds = warningSeconds * 1000;
    // the warning timeout must be smaller than the actual timeout
    if (this.warningMilliSeconds > this.timeOutMilliSeconds) {
      this.warningMilliSeconds = this.timeOutMilliSeconds;
    }
    if (this.timeOutMilliSeconds > 0) {
      this.idleSubscription = this.idle.subscribe((res) => {
        this.resetTimer();
      });
      this.startTimer();
    }
    return this.expired;
  }
  public watch(): Observable<any> {
    return this.expired;
  }
  private startTimer(updateStateOnInit?: boolean) {
    if (updateStateOnInit !== false) {
      this.expired.next(false);
    }
    this.timer = timer( this.warningMilliSeconds > 0 ? this.warningMilliSeconds : this.timeOutMilliSeconds, 1000).subscribe((res) => {
      const t = this.warningMilliSeconds > 0 ? this.timeOutMilliSeconds - this.warningMilliSeconds - 1000 * res : 0;
      const n = t <= 0 ? true : t;
      this.expired.next(n);
    });
  }
  public resetTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    if (this.expired.getValue() !== false) {
      this.expired.next(false);
    }
    this.startTimer(false);
  }
  public stopTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.expired.next(false);
    this.idleSubscription.unsubscribe();
  }
  public isRunning(): boolean {
    const t = !!this.timer && this.timer.closed !== true;
    return t;
  }
}
