<div
  class="card standalone fade invalid-api-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      <span class="icon d-block mx-auto fa fa-fw fa-2x fa-exclamation-triangle mb-2"></span>
      {{'invalid-api.title'|translate}}
    </h1>
  </div>
  <div class="card-body text-center">
    <p
    >
      {{'invalid-api.copy'|translate}}
    </p>
    <div class="details">
      <button [class]="'btn btn-light btn-block' + (showDetails ? ' active' : '')" (click)="toggleDetails()">
        <ng-container *ngIf="!showDetails">
          {{'invalid-api.toggle-show'|translate}}
        </ng-container>
        <ng-container *ngIf="showDetails">
          {{'invalid-api.toggle-hide'|translate}}
        </ng-container>
      </button>
      <div [class]="showDetails ? 'collapse show' : 'collapse'">
        <div class="collapsible-target">
          <h2 class="h5">
            {{'invalid-api.detected'|translate}}
          </h2>
          <pre><code>{{foundInfo}}</code></pre>
          <h2 class="h5">
            {{'invalid-api.required'|translate}}
          </h2>
          <pre><code>{{requiredInfo}}</code></pre>
        </div>
      </div>
    </div>
  </div>
</div>
