import {Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../gen/api/password.service';
import {User} from '../../gen/model/user';
import {StandaloneComponent} from '../standalone.component';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition} from '../animations';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';
import {
  EmailVerificationService,
  RecoveryEmailAddress,
  RecoveryEmailService,
  SetEmailAsPrimaryRequest
} from '../../gen';
import LocationUtil, {getAppBaseHref, UrlParams} from '../../util/locationUtil';
import {AppConstants} from '../AppConstants';
import {HttpErrorResponse} from '@angular/common/http';
const cloneDeep = require('lodash.clonedeep');

const toRecoveryEmailData = (v) => {
  return {
    isValid: !!v.validatedAt,
    obj: v,
    form: new FormGroup({
      id: new FormControl(v.id),
      email: new FormControl(v.value),
    }),
  };
};
const resolveVerificationURI = (resolveAsEmpty: boolean, appStateService: AppStateService, loginEmail?: boolean) => {
  const verificationUri = [];
  if (!resolveAsEmpty) {
    verificationUri.push(LocationUtil.getOwnDomainURL());
    verificationUri.push(getAppBaseHref());
    if (loginEmail) {
      verificationUri.push(AppConstants.ROUTE_VALIDATE_EMAIL);
    } else {
      verificationUri.push(AppConstants.ROUTE_VALIDATE_RECOVERY_EMAIL);
    }
    if (verificationUri[0].indexOf('?') < 0) {
      verificationUri.push('?');
    } else {
      verificationUri.push('&');
    }
    verificationUri.push(AppConstants.QP_EMAIL_VALIDATION_KEY);
    verificationUri.push('={0}');
    if (!loginEmail) {
      verificationUri.push('&');
      verificationUri.push(AppConstants.QP_EMAIL_VALIDATION_ID);
      verificationUri.push('={3}');
    }
    verificationUri.push('&');
    verificationUri.push(AppConstants.UI_SESSION_ID);
    verificationUri.push('=');
    verificationUri.push(appStateService.getAppState().uiSessionId);

    const params: UrlParams = LocationUtil.getParams([
      AppConstants.UI_SESSION_ID, AppConstants.QP_EMAIL_VALIDATION_KEY, AppConstants.QP_EMAIL_VALIDATION_ID]);
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i += 1) {
      verificationUri.push('&');
      verificationUri.push(keys[i]);
      verificationUri.push('=');
      verificationUri.push(params[keys[i]]);
    }
  }
  return verificationUri;
};

@Component({
  selector: 'app-manage-emails',
  templateUrl: './manage-emails.component.html',
  styleUrls: ['./manage-emails.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition
  ],
})
export class ManageEmailsComponent extends StandaloneComponent implements OnInit {

  profile: User;
  loginFields;
  currentEmailForm: FormGroup;
  addEmailForm: FormGroup;
  setLoginEmailForm: FormGroup;
  showRecoveryEmails: boolean;
  showEmail: boolean;
  allowChangeLogin: boolean;
  activeChangeEmail: FormGroup|undefined;
  sendEmailValidation: boolean;
  requireEmailValidation: boolean;
  isCurrentEmailValidated: boolean;

  activeRecoveryEmail: string|undefined;
  recoveryEmailData: Array<{ isValid: boolean, obj: any, form: FormGroup}> = [];

  notification: { key: string, data?: any } | undefined;


  @ViewChild('confirmLoginEmailChange', { static: true }) confirmLoginEmailChange: ElementRef;

  constructor(@Inject('PasswordApi') private passwordApi: PasswordService,
              private appStateService: AppStateService,
              private alertHandler: AlertHandler,
              private translate: TranslateService,
              @Inject('RecoveryEmailApi') private recoveryEmailApi: RecoveryEmailService,
              @Inject('EmailVerificationApi') private emailVerificationApi: EmailVerificationService,
              route: ActivatedRoute,
              router: Router,
              hostElement: ElementRef,
              configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
    if (this.appStateService.getAppState().hasNotification(AppConstants.SN_RECOVERY_EMAIL_VALIDATED)) {
      this.showNotification(AppConstants.SN_RECOVERY_EMAIL_VALIDATED);
    }
    this.requireEmailValidation = this.configuration.getProperties().requireEmailValidation;
    this.sendEmailValidation = this.configuration.getProperties().sendEmailValidation;
    this.loginFields = cloneDeep(this.configuration.getProperties().profileLoginFields);
    const emailField = this.loginFields.find((v: any) => v.key === 'email');
    this.showRecoveryEmails = this.configuration.getProperties().enableRecoveryEmails;
    this.showEmail = !!emailField;
    this.allowChangeLogin = !!emailField && !emailField.readonly;
    this.addEmailForm = new FormGroup({
      email: new FormControl('', [Validators.email]),
    });

    this.currentEmailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.focusSelectorPrefix = '.card';
  }

  ngOnInit() {
    this.init();
  }

  init() {
    super.init();
    const prof = this.getProfile();
    if (prof) {
      this.currentEmailForm.get('email').setValue(prof.email);
      this.isCurrentEmailValidated = this.appStateService.getAppState().getAuthenticationState().getExistingAuthentications()
        .findIndex((v) => v.authenticationMethod === 'emailVerification') >= 0;
    }
    this.recoveryEmailApi.listRecoveryEmails('response').subscribe((response) => {
      if (response.body && response.body.length) {
        const sorted = response.body.sort((a, b) => {
          let retVal = 0;
          if (!!a.validatedAt && !b.validatedAt) {
            retVal = -1;
          } else if (!a.validatedAt && !!b.validatedAt) {
            retVal = 1;
          }
          return retVal;
        });
        this.recoveryEmailData = sorted.map(toRecoveryEmailData);
        const qpEvId =  LocationUtil.getParams()[AppConstants.QP_EMAIL_VALIDATION_ID];
        if (!this.showRecoveryEmails &&
            this.allowChangeLogin &&
            this.recoveryEmailData.length &&
            qpEvId
        ) {
          const e = this.recoveryEmailData.find((v) => v.obj.id === qpEvId);
          if (!!e) {
            this.activeRecoveryEmail = e.obj.value;
            if (!this.requireEmailValidation || e.isValid) {
              // a new email has just been added, and it can be set as login, so open confirmation
              this.openChangeEmail(e.form);
            }
          } else {
            // requested recovery email not found, not sure if an error is needed.
            this.resetActiveRecoveryEmail();
          }
        } else if (this.showRecoveryEmails) {
          // requested recovery email id has no purpose if recovery emails are visible.
          this.resetActiveRecoveryEmail();
        }
      } else {
        this.recoveryEmailData = [];
        this.resetActiveRecoveryEmail();
      }
    }, (err) => {
      this.recoveryEmailData = [];
      this.resetActiveRecoveryEmail();
    });
  }
  showNotification(key: string, data?: any) {
    this.notification = { key };
    if (!!data) {
      this.notification.data = { ...data };
    }
  }
  hideNotification() {
    if (this.notification && this.notification.key === AppConstants.SN_RECOVERY_EMAIL_VALIDATED) {
      this.appStateService.getAppState().removeNotification(AppConstants.SN_RECOVERY_EMAIL_VALIDATED);
    }
    this.notification = undefined;
  }
  hasOnlyInvalidRecoveryEmails() {
    if (this.showRecoveryEmails) {
      return this.recoveryEmailData && this.recoveryEmailData.length > 0 && this.recoveryEmailData.filter((v) => v.isValid).length === 0;
    } else {
      const t = this.resolveActiveRecoveryEmail();
      return t && !t.isValid;
    }
  }
  resolveRecoveryEmailButtons(itm: any, templates: any): ElementRef[] {
    const retVal: ElementRef[] = [templates.delete];
    if (this.sendEmailValidation && !itm.isValid) {
      retVal.push(templates.validate);
    }
    if (this.allowChangeLogin && (!this.requireEmailValidation || itm.isValid))  {
      retVal.push(templates.setLogin);
    }
    return retVal;
  }
  getProfile(): User {
    return this.appStateService.getAppState().getAuthenticationState().getProfile();
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return this.isInputInvalid(field);
  }

  isInputInvalid(field: AbstractControl): boolean {
      return field.invalid && field.touched;
  }

  isFieldValid(field: AbstractControl): boolean {
    return this.isInputValid(field) &&
      (field === this.addEmailForm.get('email') && (!field.value || field.value === '') ? false : true)
      ;
  }

  isInputValid(field: AbstractControl): boolean {
      return field.valid && field.touched;
  }

  closeChangeEmail() {
    this.activeChangeEmail = undefined;
  }


  validateLoginEmail() {
    this.hideNotification();
    const verificationUri = resolveVerificationURI(!this.sendEmailValidation, this.appStateService, true);
    if (verificationUri.length > 0) {
      this.emailVerificationApi.sendEmailVerification({ verificationUri: verificationUri.join('') })
        .subscribe((response) => {
          this.showNotification('verifyEmailSuccess', { email: this.getProfile().email });
        }, (error) => {
          this.showNotification('verifyEmailFailed', { email: this.getProfile().email });
        })
      ;
    }
  }
  submitSetLoginEmailForm() {
    this.hideNotification();
    const setAsLoginBody = {
      password: this.setLoginEmailForm.get('password').value,
    } as SetEmailAsPrimaryRequest;
    const tid = this.setLoginEmailForm.get('id').value;
    const email = this.recoveryEmailData.find((o) => o.obj.id === tid);
    this.recoveryEmailApi.setRecoveryEmailAsPrimary(setAsLoginBody, tid )
      .subscribe((response) => {
        const isErrorResponse = response instanceof HttpErrorResponse;
        if (isErrorResponse) {
          this.showNotification('setLoginEmailFailed', { email: email ? email.obj.value : undefined});
        } else {
          const handler = () => {
            this.showNotification('setLoginEmailSuccess', { email: email ? email.obj.value : undefined});
            this.resetActiveRecoveryEmail();
            this.appStateService.reloadAuthenticationState(() => {
              this.closeChangeEmail();
              this.init();
            });
          };
          if (!this.showRecoveryEmails && response && response.length > 0) {
            this.recoveryEmailApi.deleteRecoveryEmail(response[0].id)
              .subscribe((deleteResponse) => {
                const isErr = deleteResponse instanceof HttpErrorResponse;
                if (isErr) {
                  this.showNotification('deleteEmailFailed', { email: response[0].value });
                }
                handler();
              });
          } else {
            handler();
          }
        }
      }, (error) => {
        this.showNotification('setLoginEmailFailed', { email: email ? email.obj.value : undefined});
      })
    ;
  }

  validateRecoveryEmail(recoverEntry: any) {
    this.hideNotification();
    const verificationUri = resolveVerificationURI(!this.sendEmailValidation, this.appStateService);
    if (verificationUri.length > 0) {
      this.recoveryEmailApi.sendRecoveryEmailVerification({ verificationUri: verificationUri.join('') },
        recoverEntry.obj.id as string).subscribe((response) => {
          this.showNotification('verifyEmailSuccess', { email: recoverEntry.obj.value });
        }, (error) => {
          this.showNotification('verifyEmailFailed', { email: recoverEntry.obj.value });
        })
      ;
    }
  }
  openChangeEmail(fg: FormGroup) {
    this.activeChangeEmail = fg;
    this.setLoginEmailForm =  new FormGroup({
      id: new FormControl(fg.get('id').value),
      email: new FormControl(fg.get('email').value),
      password: new FormControl('', [Validators.required]),
    });
  }
  deleteEmail(recoverEntry: any) {
    this.hideNotification();
    this.recoveryEmailApi.deleteRecoveryEmail(recoverEntry.obj.id as string).subscribe(
      (response) => {
        this.recoveryEmailData = this.recoveryEmailData.filter((v) => v.obj.id !== recoverEntry.obj.id);
        this.resetActiveRecoveryEmail();
      },
      (error) => {
        this.showNotification('deleteEmailFailed', { email: recoverEntry.obj.value });
      })
    ;
  }
  addEmail() {
    this.hideNotification();
    this.activeRecoveryEmail =  !this.showRecoveryEmails ? this.addEmailForm.get('email').value : undefined;
    const obj: RecoveryEmailAddress = {
      value: this.addEmailForm.get('email').value,
    } as any;
    const existingRE = this.resolveActiveRecoveryEmail();
    if (this.showRecoveryEmails || !existingRE) {
      const verificationUri = resolveVerificationURI(!this.sendEmailValidation, this.appStateService);
      this.recoveryEmailApi.createRecoveryEmail(
        obj,
        verificationUri.length > 0,
        verificationUri.length > 0 ? verificationUri.join('') : undefined)
        .subscribe(response => {
          this.recoveryEmailData.push(toRecoveryEmailData(response));
          this.addEmailForm.get('email').setValue('');
          if (this.sendEmailValidation) {
            this.showNotification('verifyEmailSuccess', {email: this.recoveryEmailData[this.recoveryEmailData.length - 1].obj.value});
          }
          if (!this.requireEmailValidation) {
            this.openChangeEmail(this.recoveryEmailData[this.recoveryEmailData.length - 1].form);
          }
        }, error => {
          this.showNotification('addEmailFailed');
        })
      ;
    } else {
      this.addEmailForm.get('email').setValue('');
      if (!!existingRE.validatedAt) {
        this.openChangeEmail(existingRE.form);
      } else {
        this.validateRecoveryEmail(existingRE);
      }
    }
  }
  resolveActiveRecoveryEmail(): any|undefined {
    if (this.activeRecoveryEmail) {
      if (!!this.recoveryEmailData && this.recoveryEmailData.length > 0) {
        const t = this.recoveryEmailData.find((v) => v.obj.value === this.activeRecoveryEmail);
        if (t) {
          return t;
        }
      }
    }
    return undefined;
  }
  resetActiveRecoveryEmail() {
    if (this.activeRecoveryEmail) {
      this.activeRecoveryEmail = undefined;
    }
    if (!!LocationUtil.getParams()[AppConstants.QP_EMAIL_VALIDATION_ID]) {
      // remove the evId query param if any
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {[AppConstants.QP_EMAIL_VALIDATION_ID]: null},
          queryParamsHandling: 'merge'
        });
    }
  }

  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('manage-emails.window-title');
  }

}
