import {BaseRouteReuseStrategy, ActivatedRouteSnapshot} from '@angular/router';

export class CustomReuseRouteStrategy extends BaseRouteReuseStrategy {
  /**
   * Determines whether or not the current route should be reused
   * @param future The route the user is going to, as triggered by the router
   * @param curr The route the user is currently on
   * @returns boolean basicly false if you want there to be a navigation, true if you wish to silently ignore the requested navigation.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const isSame = future.routeConfig === curr.routeConfig;
    return isSame && future && future.routeConfig && future.routeConfig.data && future.routeConfig.data.neverIgnoreNavigation === true ?
      false :
      isSame
    ;
  }
}
