<!--suppress HtmlFormInputWithoutLabel -->
<div [class]="controlWrapperClass()">
  <ng-template #formControl>
    <textarea
      *ngIf="type === 'textarea'"
      [id]="resolveId()"
      [name]="field"
      [formControlName]="field"
      [attr.autofocus]="autofocus"
      class="form-control"
      [ngClass]="resolvedInputNGClass"
      [attr.autocomplete]="autocomplete !== undefined ? autocomplete : false"
      (focus)="onInputFocus()"
      (paste)="onPaste($event)"
      [placeholder]="placeholder !== undefined ? placeholder : false"
      [readonly]="readonly !== undefined && readonly !== false"
      rows="4"
    ></textarea>
    <input
      *ngIf="type !== 'select' && type !== 'textarea'"
      [id]="resolveId()"
      [name]="field"
      [type]="type === 'password' && showPassword ? 'text' : type"
      [formControlName]="field"
      [attr.autofocus]="autofocus"
      class="form-control"
      [ngClass]="resolvedInputNGClass"
      [autocomplete]="autocomplete !== undefined ? autocomplete : false"
      (focus)="onInputFocus()"
      (paste)="onPaste($event)"
      [placeholder]="placeholder !== undefined ? placeholder : false"
      [readonly]="readonly !== undefined && readonly !== false"
    />
    <button
      *ngIf="type === 'password'"
      class="toggle-password"
      type="button"
      (click)="toggleShowPassword()"
      placement="auto"
      container="body"
      [ngbTooltip]="!showPassword ? ('form-input.toggle-password-visibility-tooltip.show'|translate) : ('form-input.toggle-password-visibility-tooltip.hide'|translate)"
    ><span class="fa fa-fw fa-eye" *ngIf="!showPassword"></span><span class="fa fa-fw fa-eye-slash" *ngIf="showPassword"></span></button>
    <select
      *ngIf="type === 'select'"
      [id]="resolveId()"
      [name]="field"
      [formControlName]="field"
      [attr.autofocus]="autofocus"
      class="form-control with-placeholder-label"
      [ngClass]="resolvedInputNGClass"
      (focus)="onInputFocus()"
    >
      <option *ngFor="let itm of selectOptions" [value]="itm.value">{{itm.label}}</option>
    </select>
  </ng-template>
  <ng-template #content><ng-content></ng-content></ng-template>
  <div [class]="'input-group' + (size ? ' input-group-' + size : '')" *ngIf="prepend || append">
    <div class="input-group-prepend" *ngIf="prepend && !isArray(prepend)">
      <ng-container [ngTemplateOutlet]="prepend"></ng-container>
    </div>
    <ng-container *ngIf="prepend && isArray(prepend)">
      <div class="input-group-prepend" *ngFor="let pre of prepend">
        <ng-container [ngTemplateOutlet]="pre"></ng-container>
      </div>
    </ng-container>
    <div class="input-group-input-wrapper">
      <ng-container [ngTemplateOutlet]="formControl"></ng-container>
      <label
        [for]="resolveId()"
        [class]="labelClass()"
      >{{label}}</label>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
    <div class="input-group-append" *ngIf="append && !isArray(append)">
      <ng-container [ngTemplateOutlet]="append"></ng-container>
    </div>
    <ng-container *ngIf="append && isArray(append)">
      <div class="input-group-append" *ngFor="let ap of append">
        <ng-container [ngTemplateOutlet]="ap"></ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!prepend && !append" [ngTemplateOutlet]="formControl"></ng-container>
  <ng-container *ngIf="!prepend && !append" [ngTemplateOutlet]="content"></ng-container>
  <label
    *ngIf="!prepend && !append"
    [for]="resolveId()"
    [class]="labelClass()"
  >{{label}}</label>
</div>
