import {AfterViewInit, Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {AppConstants} from '../AppConstants';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() class;
  @HostBinding('class') hostClass;
  /**
   * Ready state of the component for init animations/UI triggers.
   * Set to true once the component has been drawn
   */
  private ready: boolean;
  constructor() { }

  ngOnInit() {
    if (this.class) {
      this.hostClass = this.class + ' loading';
    } else {
      this.hostClass = 'loading';
    }
  }
  ngAfterViewInit() {
    setTimeout(() => { this.ready = true; this.hostClass = this.class; }, 1);
  }
  ngOnChanges() {}

  getAppConstant(constant: string): string {
      return AppConstants[constant];
  }
}
