import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../AppConstants';
import {ParamsService} from '../params.service';
import {Logger} from '../../util/logger';
import {Observable} from 'rxjs';
import {Invitation, InvitationService, User} from '../../gen';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';
import {ProcessingIndicatorService} from '../processing-indicator.service';


interface JoinParams {
  accept?: string;
  decline?: string;
  skip?: string;
  token?: string;
  id?: string;
  email?: string;
}

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JoinComponent extends StandaloneComponent implements OnInit, OnDestroy {

  private destroyed = false;
  requiredParametersMissing = false;
  parameterMismatch = false;
  /* null, or if mismatched the value of users session email */
  emailMismatch: string = null;
  loadInvitationFailed = false;
  acceptFailed = false;
  declineFailed = false;
  invitation: Invitation;
  totalInvitations: number = 0;
  currentInvitationIndex: number = 0;
  private params: JoinParams;
  invitationForm = new FormGroup({
    token: new FormControl('', []),
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private translate: TranslateService,
    private logger: Logger,
    private processingService: ProcessingIndicatorService,
    @Inject('InvitationApi') private invitationService: InvitationService,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
    this.idOrTokenRequiredValidator = this.idOrTokenRequiredValidator.bind(this);
    this.resolveField('token').setValidators([this.idOrTokenRequiredValidator]);
  }
  continueToNext() {
    const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRoute([
        AppConstants.QP_EMAIL,
        AppConstants.QP_LOGIN_HINT,
        AppConstants.QP_INVITATION_ID,
        AppConstants.QP_INVITATION_TOKEN,
        AppConstants.QP_HANDLE_CHALLENGE,
      ])
    ;
    this.logger.debug('Invitation completed: %o', t);
    if (typeof t === 'string') {
      this.processingService.show();
      window.location.href = t as string;
    } else if (t) {
      this.router.navigate([t.path], {
        queryParams: t.queryParams,
        fragment: t.fragment,
      });
    }
  }
  idOrTokenRequiredValidator(ctrl: FormControl) {
    let retVal = null;
    if (!this.resolveField('token').value && (!this.params || !this.params.id)) {
      retVal = { 'idOrTokenRequired': {value: ctrl.value} };
    }
    return retVal;
  }
  ngOnInit() {
    super.init();
    this.readParams().then((val) => {
      this.params = val;
      if (val) {
        if (val.token) {
          this.resolveField('token').setValue(val.token);
        }
        let hasEmail;
        if (val.email) {
          hasEmail = true;
          this.resolveField('email').setValue(val.email);
        } else if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
          const user: User = this.appStateService.getAppState().getAuthenticationState().getProfile();
          if (user && user.email) {
            hasEmail = true;
            this.resolveField('email').setValue(user.email);
          }
        }
        if (val.token && hasEmail) {
          this.loadInvitation();
        } else if (val.id) {
          this.loadInvitation();
        } else {
          this.requiredParametersMissing = true;
        }
        this.resolveField('token').updateValueAndValidity();
        this.invitationForm.updateValueAndValidity();
      }
    });
    const remainingInvitations = this.appStateService.getAppState().getAuthenticationState().getRemainingChallenges()
      .filter((c) => c.authenticationMethod === AppConstants.AC_AM_INVITATION)
      .length
    ;
    this.currentInvitationIndex = this.appStateService.getAppState().getAuthenticationState().getAcceptedInvitations().length +
      this.appStateService.getAppState().getAuthenticationState().getDeclinedInvitations().length +
      this.appStateService.getAppState().getAuthenticationState().getSkippedInvitations().length;
    this.totalInvitations = remainingInvitations + this.currentInvitationIndex;
  }
  private loadInvitation(callback?: (inv: Invitation | any) => void) {
    if (this.resolveField('token').value && this.resolveField('email').value) {
      this.invitationService.getInvitationByToken(
        this.resolveField('token').value,
        this.resolveField('email').value,
        'response',
        false
      ).subscribe((inv) => {
        if (callback) {
          callback(inv.body as Invitation);
        } else {
          this.invitationLoaded(inv.body as Invitation);
        }
      }, (err) => {
        if (callback) {
          callback(err);
        } else {
          this.invitationLoadFailed(err);
        }
      });
    } else if (this.params.id) {
      this.invitationService.getInvitationById(this.params.id, 'response', false)
        .subscribe((inv) => {
          if (callback) {
            callback(inv.body as Invitation);
          } else {
            this.invitationLoaded(inv.body as Invitation);
          }
        }, (err) => {
          if (callback) {
            callback(err);
          } else {
            this.invitationLoadFailed(err);
          }
        })
      ;
    } else {
      this.invitationLoadFailed({ error: 'required_parameters_missing' });
    }
  }
  private invitationLoaded(inv: Invitation) {
    if (!this.destroyed) {
      this.logger.debug('invitation loaded: %o', inv);
      this.invitation = inv;
      if (this.params.id && this.params.id !== this.invitation.id) {
        this.parameterMismatch = true;
      }
      if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
        const user: User = this.appStateService.getAppState().getAuthenticationState().getProfile();
        if (!user.email || user.email.toLowerCase() !== inv.email.toLowerCase()) {
          this.emailMismatch = user.email || 'undefined';
          if (user.email) {
            this.emailMismatch = user.email;
          } else {
            this.translate.get('join.undefined-email-label').subscribe((res) => {
              this.emailMismatch = res;
            }, err => {
              this.emailMismatch = 'join.undefined-email-label';
            });
          }
        } else if (this.params.accept && this.params.accept === this.params.token) {
          this.accept();
        }
      }
    }
  }
  private invitationLoadFailed(err) {
    this.logger.debug('loading invitation failed %o', err);
    this.loadInvitationFailed = true;
  }
  ngOnDestroy() {
    this.destroyed = true;
  }
  private resolveField(s: string): AbstractControl {
    return this.invitationForm.get(s);
  }
  private resolvePathForSession(): string {
    let retVal = null;
    if (this.invitation && this.invitation.memberStatus !== 'user') {
      retVal = AppConstants.PATH_REGISTER;
    } else {
      retVal = AppConstants.PATH_LOGIN;
    }
    return retVal;
  }
  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.route,
    });
  }
  close() {
    if (!this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      const qp = {
        [AppConstants.QP_INVITATION_TOKEN]: null,
        [AppConstants.QP_INVITATION_ID]: null,
        [AppConstants.QP_EMAIL]: this.resolveField('email').value ? this.resolveField('email').value : null,
        [AppConstants.QP_LOGIN_HINT]: undefined,
      };
      this.router.navigate([this.resolvePathForSession()], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams: qp,
      });
    } else if (this.requiredParametersMissing || this.loadInvitationFailed || this.emailMismatch) {
      // App.component triggers a redirect to next before this is hit, when applicable.
      // Hopefully always, but at least in all the test cases. This redirect should probably be conditional,
      // but we don't have proper data here at the moment.
      this.continueToNext();
    }
  }
  accept() {
    this.logger.debug('accept');
    if (this.params.id) {
      this.invitationService.acceptInvitationById(this.params.id, 'response', false)
        .subscribe(() => {
          this.appStateService.getAppState().getAuthenticationState().acceptInvitation(this.invitation.id);
          this.continueToNext();
        }, (err) => {
          this.acceptFailed = true;
        })
      ;
    } else if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      this.invitationService.acceptInvitation(
        this.resolveField('token').value,
        this.resolveField('email').value,
        'response',
        false
      ).subscribe((inv) => {
        this.appStateService.getAppState().getAuthenticationState().acceptInvitation((inv.body as Invitation).id);
        // successful accept may validate email, so we need to reload the state
        this.appStateService.reloadAuthenticationState(() => {
          this.continueToNext();
        });
      }, (err) => {
        this.acceptFailed = true;
      });
    } else {
      this.invitationService.getInvitationByToken(
        this.resolveField('token').value,
        this.resolveField('email').value,
        'response',
        false
      ).subscribe((inv) => {
        this.params.accept = null;
        this.invitationLoaded(inv.body as Invitation);
        const qp = {
          [AppConstants.QP_REQUIRE_CHALLENGE]: AppConstants.AC_AM_INVITATION_ACCEPTED,
          [AppConstants.QP_INVITATION_TOKEN]: this.resolveField('token').value,
          [AppConstants.QP_EMAIL]: this.resolveField('email').value,
          [AppConstants.QP_LOGIN_HINT]: undefined,
        };
        this.router.navigate([this.resolvePathForSession()], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          queryParams: qp,
        });
      }, (err) => {
        this.acceptFailed = true;
      });
    }
  }
  decline() {
    this.logger.debug('decline');
    const declinedHandler = (inv) => {
      if (!this.appStateService.getAppState().getAuthenticationState().hasSession()) {
        const qp = {
          [AppConstants.QP_INVITATION_ID]: null,
          [AppConstants.QP_INVITATION_TOKEN]: null,
          [AppConstants.QP_EMAIL]: this.resolveField('email').value,
          [AppConstants.QP_LOGIN_HINT]: undefined,
        };
        this.router.navigate([this.resolvePathForSession()], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          queryParams: qp,
        });
      } else {
        this.appStateService.getAppState().getAuthenticationState().declineInvitation((inv.body as Invitation).id);
        this.continueToNext();
      }
    };
    if (this.params.id) {
      this.invitationService.declineInvitationById(this.params.id, 'response', false)
        .subscribe(declinedHandler, (err) => { this.declineFailed = true; })
      ;
    } else {
      this.invitationService.declineInvitation(
        this.resolveField('token').value,
        this.resolveField('email').value,
        'response',
        false
      ).subscribe(declinedHandler, (err) => { this.declineFailed = true; });
    }
  }
  skip() {
    this.logger.debug('skip');
    if (this.invitation) {
      this.appStateService.getAppState().getAuthenticationState().skipInvitation(this.invitation.id);
      if (!this.appStateService.getAppState().getAuthenticationState().hasSession()) {
        const qp = {
          [AppConstants.QP_INVITATION_TOKEN]: null,
          [AppConstants.QP_INVITATION_ID]: null,
          [AppConstants.QP_EMAIL]: this.resolveField('email').value,
          [AppConstants.QP_LOGIN_HINT]: undefined,
        };
        this.router.navigate([this.resolvePathForSession()], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          queryParams: qp,
        });
      } else {
        this.continueToNext();
      }
    } else {
      this.loadInvitation((inv: Invitation | any) => {
        if (inv && inv.id) {
          this.invitationLoaded(inv);
          this.skip();
        } else {
          this.invitationLoadFailed(inv);
        }
      });
    }
  }
  allowSkip(): boolean {
    // Currently always skippable
    return true;
  }
  invitationCopy(): string {
    return !this.requiredParametersMissing && this.invitation ? this.invitation.invitationScopeInformation : null;
  }
  private readParams(): Promise<JoinParams> {
    return new Promise<JoinParams>((resolve) => {
      const acceptPromise = this.paramsService.getParam(AppConstants.QP_HANDLE_CHALLENGE);
      const tokenPromise = this.paramsService.getParam(AppConstants.QP_INVITATION_TOKEN);
      const emailPromise = this.paramsService.getParam(AppConstants.QP_EMAIL);
      const idPromise = this.paramsService.getParam(AppConstants.QP_INVITATION_ID);
      Promise.all([acceptPromise, tokenPromise, emailPromise, idPromise]).then((values) => {

        const result: JoinParams = {
          accept: values[0] === AppConstants.AC_AM_INVITATION_ACCEPTED ? values[1] : null,
          token: values[1],
          email: values[2],
          id: values[3],
        };

        resolve(result);
      });
    });
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('join.window-title');
  }
}
