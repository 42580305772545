<div
  class="card standalone validate-email-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <app-duke-alert
    type="danger"
    *ngIf="!!showServerError"
    alertClass="mb-0"
    dismissible="true"
    (close)="setShowServerError(undefined)"
  >
    <p
      *ngIf="showServerError === getAppConstant('ERROR_INSUFFICIENT_AUTHENTICATIONS')"
      [innerHTML]="'validate-email.insufficient-authentications-error'|translate"
      class="mb-0"
    ></p>
  </app-duke-alert>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'validate-email.title'|translate}}
    </h1>
  </div>
  <form
    [formGroup]="sendEmailForm"
    (ngSubmit)="sendEmail()"
  >
    <div class="card-body">
      <p class="text-center"
         autofocus
         [ngClass]="{
            'mb-0': !!recoveryEmail
          }"
         *ngIf="!hasError() && !alreadyValidated && (!isRecoveryEmail || recoveryEmail)">
        <span [innerHTML]="'validate-email.info-copy'|translate:{ email: !!getEmail() ? ' (<strong>' + getEmail() + '</strong>)' : ''}"></span>
      </p>
      <p class="text-center mb-0" *ngIf="!hasError() && !alreadyValidated && !isRecoveryEmail"
         placement="auto"
         [ngbTooltip]="('validate-email.check-and-continue-button.tooltip'|translate)"
      >
        <button
          data-test-check-and-continue
          class="btn btn-link btn-block font-italic"
          type="button"
          (click)="checkAndContinue()"
        >{{'validate-email.check-and-continue-button.label'|translate}}</button>
      </p>

      <app-duke-alert
        type="danger"
        data-test-invalid-link
        *ngIf="isRecoveryEmail && !recoveryEmailId"
        alertClass="mb-0 static"
      >
        <p class="mb-0">
          <strong>{{'validate-email.recovery-email-id-required'|translate}}</strong>
          {{'validate-email.recovery-email-id-required-info'|translate}}
        </p>
      </app-duke-alert>
      <app-duke-alert
        type="danger"
        data-test-invalid-link
        *ngIf="recoveryEmailNotFound()"
        alertClass="mb-0 static"
      >
        <p class="mb-0">
          <strong>{{'validate-email.recovery-email-not-found'|translate}}</strong>
          {{'validate-email.recovery-email-not-found-info'|translate}}
        </p>
      </app-duke-alert>
      <app-duke-alert
        type="danger"
        data-test-already-validated-message
        *ngIf="(hasError() || (!key && !isRecoveryEmail)) && !!alreadyValidated"
        alertClass="mb-0 static"
      >
        <p class="mb-0">
          <strong *ngIf="isRecoveryEmail">{{'validate-email.submit-error.message.part-validated-recovery'|translate}}</strong>
          <strong *ngIf="!isRecoveryEmail">{{'validate-email.submit-error.message.part-validated'|translate}}</strong>
          {{'validate-email.submit-error.message.part-validated-continue-info'|translate}}
        </p>
      </app-duke-alert>
      <app-duke-alert
        type="danger"
        data-test-submit-failed-message
        *ngIf="hasError() && !alreadyValidated && (validationFailed || tooManyAttemptsError || hasTooManyAttemptsTimeout()) && (!isRecoveryEmail || recoveryEmail)"
        alertClass="mb-0 static"
      >
          <p *ngIf="validationFailed">
            {{'validate-email.submit-error.message.failed'|translate}}:
            <ng-container *ngIf="!validationCodeExpired && isFieldInvalid(validationForm.get('key'))">
              {{'validate-email.submit-error.message.part-invalid-link'|translate}}
            </ng-container>
            <ng-container *ngIf="validationCodeExpired && isFieldInvalid(validationForm.get('key'))">
              {{'validate-email.submit-error.message.part-expired-link'|translate}}
            </ng-container>
          </p>
          <p class="mb-0" *ngIf="!hasTooManyAttemptsTimeout() && !tooManyAttemptsError">
            {{'validate-email.submit-error.message.part-retry'|translate}}
          </p>

        <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'validate-email.submit-error.message.tooManyAttempts' | translate"></p>
        <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'validate-email.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
        <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'validate-email.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
      </app-duke-alert>
    </div>
    <div
      class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <small class="text-danger order-2 mr-2 text-right" *ngIf="!alreadyValidated && sendEmailTimeout > 0 && (!isRecoveryEmail || !!recoveryEmail)">
          <span class="icon fa fa-fw fa-ban mr-2"></span>
          <span [innerHTML] = "'validate-email.info.resend-wait-copy' | translate: { timeout: sendEmailTimeout }"></span>
        </small>
        <button
            *ngIf="!alreadyValidated && (!isRecoveryEmail || !!recoveryEmail)"
            data-test-send-email-validation
            class="btn btn-info float-right order-1"
            type="submit"
            [disabled]="sendEmailTimeout > 0"
        >{{'validate-email.info.resend-key-button.label'|translate}}</button>
        <a
          *ngIf="!alreadyValidated && (!isRecoveryEmail || !!recoveryEmail)"
          class="btn btn-secondary order-3 mr-auto" href="#"
          (click)="cancel()"
        >{{'validate-email.cancel-button.label'|translate}}</a>
        <a
          *ngIf="alreadyValidated || (isRecoveryEmail && !recoveryEmail)"
          class="btn btn-secondary float-right order-1"
          href="#"
          (click)="cancel()"
        >{{'validate-email.continue-button.label'|translate}}</a>
      </div>
    </div>
  </form>
  <form
    [formGroup]="validationForm"
    (ngSubmit)="submit()"
  >
      <input
             type="hidden"
             name="key"
             id="key"
             formControlName="key"
      />
  </form>
  <app-duke-alert
    type="success"
    *ngIf="emailsent"
    dismissible="true"
    data-test-email-validation-sent
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification(getAppConstant('SN_LOGIN_EMAIL_VALIDATION_SENT'));"
  >
    <p [innerHTML]="'validate-email.info.key-sent-copy-without-code'|translate:{ email: '<strong>' + resolveEmail() + '</strong>' }">
    </p>
    <p class="mb-0">
      {{'validate-email.info.resend-key-copy'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    type="danger"
    *ngIf="emailfailed"
    data-test-email-validation-send-failed
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); emailfailed = false;"
  >
    <p class="mb-0">
      {{'validate-email.info.key-send-failed-notification'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    type="danger"
    *ngIf="validationCheckFailed"
    data-test-validation-check-failed
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); validationCheckFailed = false;"
  >
    <p class="mb-0">
      {{'validate-email.check-and-continue-failed'|translate}}
    </p>
  </app-duke-alert>
</div>
