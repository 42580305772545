import {
  AcceptAgreementChallenge,
  AcceptAgreementsChallenge,
  AgreementsByUserAttributes,
  AgreementService,
  AgreementUserAttributes, AuthenticationChallenge, AuthenticationFlows
} from '../gen';

/**
 * Selects a set of agreements
 */
export async function selectAgreements(agreementSets: AgreementsByUserAttributes[], attributes: AgreementUserAttributes):
  Promise<AcceptAgreementChallenge|undefined> {
  if (agreementSets && agreementSets.length) {
    // attempt get set with matching locale
    let agreementSet = agreementSets.find(
      (as) => as.locale?.toLowerCase() === attributes.locale?.toLowerCase());
    if (!agreementSet) {
      if (attributes.locale.indexOf('-') > 0) {
        // match not found, attempt to get set with only the language code
        const loc = attributes.locale.split('-')[0];
        agreementSet = agreementSets.find((as) => as.locale?.toLowerCase() === loc?.toLowerCase());
      }
      if (!agreementSet) {
        // match not found, attempt to get set without locale
        agreementSet = agreementSets.find((as) => as.locale === undefined);
      }
    }
    if (!agreementSet) {
      // match not found, use first
      agreementSet = agreementSets[0];
    }
    return agreementSet.challengeChain;
  } else {
    return undefined;
  }
}

/**
 * Flattens challenge chain following first item on each level
 */
export async function flattenChallenges(c: AuthenticationChallenge|undefined): Promise<AuthenticationChallenge[]|undefined> {
  if (c) {
    const result: AuthenticationChallenge[] = [c];
    let t = c.challenges ? c.challenges[0] : undefined;
    while (t) {
      result.push(t);
      t = t.challenges ? t.challenges[0] : undefined;
    }
    return result;
  }
}

/**
 * Fetches user agreements and ads received timestamp
 */
export function listUsersAgreementsWithReceivedTime(agreementApi: AgreementService) {
  return new Promise<{ agreements: AcceptAgreementChallengeWithReceivedTime[]|undefined, locale: string|undefined }|undefined>(
    (resolve, reject) => {
      agreementApi.listAgreements('response').subscribe((response) => {
          const ts = new Date().getTime();
          if (response.body && response.body.length > 0) {
            flattenChallenges(response.body[0].challengeChain).then((c) => {
              resolve({
                locale: response.body[0].locale,
                agreements: (c as AcceptAgreementChallengeWithReceivedTime[]).map((m) => ({
                  ...m,
                  received: ts,
                })),
              });
            });
          } else {
            resolve(undefined);
          }
        },
        (er) => {
          reject(er);
        });
    });
}

export interface AcceptAgreementChallengeWithReceivedTime extends AcceptAgreementChallenge {
  received?: number;
}
export interface AcceptAgreementsChallengeWithReceivedTime extends AcceptAgreementsChallenge {
  received?: number;
}
