<div
  class="card standalone fade join-component"
  [ngClass]="{
    'show': isReady(),
    'error': requiredParametersMissing || loadInvitationFailed
  }"
>
  <h1 class="card-header text-center" *ngIf="!requiredParametersMissing && !loadInvitationFailed">
    {{'join.title'|translate}}
    <span
      data-test-current-of-total
      *ngIf="totalInvitations > 1"
    >
      ({{currentInvitationIndex + 1}}/{{totalInvitations}})
    </span>
  </h1>
  <h1 class="card-header text-center" *ngIf="requiredParametersMissing || loadInvitationFailed">
    <span class="icon d-block mx-auto fa fa-fw fa-2x fa-exclamation-triangle mb-2"></span>
    {{'join.error-title'|translate}}
  </h1>
  <div class="card-body">
      <p
        class="mb-0 text-center"
        *ngIf="requiredParametersMissing"
        data-test-params-missing="true"
      >
        {{'join.required-parameters-missing'|translate}}
      </p>
      <p
        class="mb-0 text-center"
        *ngIf="loadInvitationFailed"
        data-test-load-failed="true"
      >
        {{'join.invitation-load-failed'|translate}}
      </p>
    <app-duke-alert
      type="danger"
      *ngIf="emailMismatch"
      alertClass="mb-0 static"
      data-test-email-mismatch="true"
    >
      <p
        class="mb-0"
        [innerHTML]="'join.invitation-email-mismatch'|translate: { userEmail: emailMismatch, invitationEmail: invitation.email}"
      ></p>
    </app-duke-alert>
    <app-duke-alert
      type="danger"
      *ngIf="acceptFailed"
      alertClass="mb-0 static"
      data-test-accept-failed="true"
    >
      <p class="mb-0">
        {{'join.invitation-accept-failed'|translate}}
      </p>
    </app-duke-alert>
    <app-duke-alert
      type="danger"
      *ngIf="declineFailed"
      data-test-decline-failed="true"
      alertClass="mb-0 static"
    >
      <p class="mb-0">
        {{'join.invitation-decline-failed'|translate}}
      </p>
    </app-duke-alert>
    <p class="mb-0" *ngIf="invitationCopy() && !emailMismatch && !acceptFailed && !declineFailed">{{invitationCopy()}}</p>
    <p class="mb-0" *ngIf="!invitationCopy() && invitation && !emailMismatch && !acceptFailed && !declineFailed">{{'join.invitation.copy'|translate}}</p>
  </div>
  <div class="card-footer">
    <div class="d-flex align-items-center flex-row-reverse">
      <ng-container *ngIf="requiredParametersMissing || loadInvitationFailed || emailMismatch || acceptFailed || declineFailed">
        <button
          type="button"
          class="btn btn-secondary"
          data-test-continue-button="true"
          [ngClass]="{
            'btn-block': requiredParametersMissing || loadInvitationFailed,
            'btn-lg': requiredParametersMissing || loadInvitationFailed
          }"
          (click)="close()"
        >
          {{'join.continue-button.label'|translate}}
        </button>
      </ng-container>
      <ng-container *ngIf="!requiredParametersMissing && !loadInvitationFailed && !emailMismatch && !acceptFailed && !declineFailed">
        <button
          type="button"
          class="btn btn-success"
          (click)="accept()"
          [disabled]="!invitationForm.valid"
          data-test-accept-button="true"
        >
          {{'join.accept-button.label'|translate}}
        </button>
        <button
          type="button"
          class="btn btn-danger mr-2"
          (click)="decline()"
          [disabled]="!invitationForm.valid"
          data-test-decline-button="true"
        >
          {{'join.decline-button.label'|translate}}
        </button>
        <button
          type="button"
          class="btn btn-secondary mr-auto"
          *ngIf="allowSkip()"
          [disabled]="!invitationForm.valid"
          (click)="skip()"
          data-test-skip-button="true"
        >
          {{'join.skip-button.label'|translate}}
        </button>
      </ng-container>
    </div>
  </div>
</div>
