import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ParamsService} from '../params.service';
import {AppConstants} from '../AppConstants';
import {DomSanitizer} from '@angular/platform-browser';
import {getApiBasePath} from '../../api/real-api/api-base-path';
import {Logger} from '../../util/logger';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import LocationUtil from '../../util/locationUtil';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';
import {ProcessingIndicatorService} from '../processing-indicator.service';
import XWindowEvents from '../../x-window-events';

interface LogoutComponentParams {
  initiator: string;
  initiatorType?: string;
}
interface ConnectedApplication {
  applicationId: string;
  applicationName?: string;
  protocol?: string;
}
interface LogoutResponse {
  applicationId: string;
  applicationType: string;
  type: string;
  status: string;
}

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent extends StandaloneComponent implements OnInit, OnDestroy {

  consumers: Array<ConnectedApplication> = [];
  private consumersCompletedTimeoutIds = {};
  providers: Array<ConnectedApplication> = [];
  private trustedOrigins: Array<string>;
  private destroyed = false;
  /**
   * To avoid flashing content, we store the show time and once completed we check if the
   * duration was longer than the treshold, and if so, we wait for the remaining duration of
   * the minimumDisplayTime
   */
  private startTime: number;
  private timeTreshold = 50;
  private minimumDisplayTime = 1000;
  private maximumResponseWaitTime = 5000;
  @HostListener('window:message', ['$event'])
  onMessage(e: MessageEvent)  {
    if (e && this.trustedOrigins.indexOf(e.origin) >= 0 && e.data && e.data.type === 'logout-response') {
      try {
        const obj = e.data as LogoutResponse;
        if (obj.status === 'completed') {
          if (obj.applicationType.toLowerCase().indexOf('consumer') >= 0) {
            if (this.consumersCompletedTimeoutIds[obj.applicationId]) {
              clearTimeout(this.consumersCompletedTimeoutIds[obj.applicationId]);
              delete this.consumersCompletedTimeoutIds[obj.applicationId];
            }
            this.consumerLoggedOut(obj.applicationId);
          }
        }
      } catch (e) {}
    }
  }
  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    private translate: TranslateService,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private sanitizer: DomSanitizer,
    private logger: Logger,
    private processingService: ProcessingIndicatorService,
    configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
    const temp = getApiBasePath().split('/');
    // assign api server and self as trusted origins, should be equal in all real depoloyments, but may be different with test setups
    this.trustedOrigins = [
      temp[0] + '//' + temp[2],
      LocationUtil.getOwnDomainURL(),
    ];

  }
  consumerLoggedOut(id: string) {
    if (id) {
      this.consumers = this.consumers.filter(itm => itm.applicationId !== id);
      if (this.consumers.length === 0 && this.providers.length === 0) {
        this.logoutCompleted();
      } else if (this.consumers.length === 0) {
        this.processingService.show();
        window.location.href = this.getProviderLogoutUrl(this.providers[0]);
      }
    }
  }
  getConsumerLogoutUrl(itm: ConnectedApplication): any {
    if (!this.consumersCompletedTimeoutIds[itm.applicationId]) {
      this.consumersCompletedTimeoutIds[itm.applicationId] = setTimeout(() => {
        delete this.consumersCompletedTimeoutIds[itm.applicationId];
        this.consumerLoggedOut(itm.applicationId);
      }, this.maximumResponseWaitTime);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      getApiBasePath() + '/sessions/clients/' + itm.applicationId + '/signOut');
  }
  getProviderLogoutUrl(itm: ConnectedApplication): any {
    let retVal = getApiBasePath() + '/sessions/externalIdentityProviders/' + itm.applicationId + '/signOut';
    const n = new URL(window.location.href).searchParams.get('next');
    if (!!n) {
      retVal = retVal + '?next=' + encodeURIComponent(n) ;
    }
    return retVal;
  }
  ngOnInit() {
    this.startTime = new Date().getTime();
    this.readParams().then((result) => {
      if (!this.appStateService.getAppState().getAuthenticationState().hasSession()) {
        this.logoutCompleted();
        return;
      }
      this.appStateService.endSession(false, false).subscribe((next) => {
        this.logger.debug('logout success');
        this.logoutCompleted();
      }, (err) => {
        this.logger.debug('logout err');
        if (err && err.error && err.error.sessionExists === false) {
            this.logoutCompleted();
        } else if (err && err.error && err.error.error === 'sso_sessions_exist') {
          if (err.error.consumers && err.error.consumers.length > 0) {
            this.consumers = err.error.consumers as Array<ConnectedApplication>;
            if ((result.initiatorType && result.initiatorType.toLowerCase().indexOf('consumer') >= 0) ||
                 !result.initiatorType) {
              this.consumers = this.consumers.filter(
                (val) => val.applicationId !== result.initiator
              );
            }
          } else {
            this.consumers = [];
          }
          if (err.error.providers && err.error.providers.length > 0) {
            this.providers = err.error.providers as Array<ConnectedApplication>;
            if ((result.initiatorType && result.initiatorType.toLowerCase().indexOf('provider') >= 0) ||
              !result.initiatorType) {
              this.providers = this.providers.filter(
                (val) => val.applicationId !== result.initiator
              );
            }
          } else {
            this.providers = [];
          }
          if ((!this.consumers || this.consumers.length === 0) && this.providers && this.providers.length > 0) {
            this.processingService.show();
            window.location.href = this.getProviderLogoutUrl(this.providers[0]);
          }
        }
        this.logger.debug(err);
      });
    });

  }
  logoutCompleted() {
    this.logger.debug('logout completed');
    const handler = () => {
      if (!this.destroyed) {
        const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRouteAfterLogout(
          [AppConstants.QP_ERROR, AppConstants.QP_FORCE]);
        this.logger.debug('Logout completed: %o', t);
        if (typeof t === 'string') {
          this.processingService.show();
          window.location.href = t as string;
        } else if (t) {
          this.router.navigate([t.path], {
            queryParams: t.queryParams,
            fragment: t.fragment,
          });
        }
      }
    };
    const callback = () => {
      this.appStateService.getAppState().clearNotifications();
      XWindowEvents.fireLogout();
      const t = new Date().getTime();
      const delta = t - this.startTime;
      if (delta > this.timeTreshold) {
        let wait = this.minimumDisplayTime - delta;
        if (wait < 1) {
          wait = 1;
        }
        setTimeout(handler, wait);
      } else {
        handler();
      }
    };
    if (!this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      // no local session, invoke callback immediately
      callback();
    } else {
      this.appStateService.endSession(true, true).subscribe(callback, callback);
    }
  }
  ngOnDestroy() {
    this.destroyed = true;
  }
  private readParams(anim?: boolean): Promise<LogoutComponentParams> {
    return new Promise<LogoutComponentParams>((resolve) => {
      const initiatorPromise = this.paramsService.getParam(AppConstants.QP_LOGOUT_INITIATOR);
      const initiatorTypePromise = this.paramsService.getParam(AppConstants.QP_LOGOUT_INITIATOR_TYPE);
      Promise.all([initiatorPromise, initiatorTypePromise]).then((values) => {

        const result: LogoutComponentParams = {
          initiator: values[0],
          initiatorType: values[1],
        };

        resolve(result);
      });
    });
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('logout.window-title');
  }
}
